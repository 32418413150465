import React, { Suspense, useEffect, useState } from "react";
import { Grid, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Accordion from "./Accordion";
import { useMediaQuery } from "react-responsive";
export default function FAQ() {
  const { t } = useTranslation();
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  const accordionData = [
    {
      title: "How is the price of life insurance decided?",
      content: `The premium price depends on many factors and its calculation is complex, but to sum it up; insured age, gender, health condition, smoking status and lifestyle are the main factors that life insurance companies will consider to assess the risk of insuring someone. you also have to know that cost of insurance might be different than the premium price, depending on the life insurance product you choose.`,
    },
    {
      title: "Can life insurance claims get declined?",
      content: `Yes! but only if you submit your application in bad faith. life insurance is a contract between the policy owner and the insurance company. in many cases insurance company ask questions and based on the insured answers offers insurance coverage. Therefore, as long you answer the questions truthfully, your claim will not be declined since the insurance company must honour the contract in a highly regulated industry.`,
    },
    {
      title: "How does get covered’s policy recommendation works?",
      content: `Get covered was built based on years of experience with life insurance experts and with the help of advanced technology, it can guide anyone to find out how much coverage amount and what policy type suits their needs the most. by using concepts like kyc, and financial analytics get covered produces a custom life insurance solution for its users.`,
    },
    {
      title: "How can i choose a good life insurance company?",
      content: `Choosing a life insurance company is simple yet crucial. each company you have approached for a quote has probably sent you a different price, and you are now faced with which one to choose. some are more well recognized but more expensive, while others are offering you a more affordable choice. you should check the financial health, payout ratio and years of operation of the company and take into consideration their overall rating. i sound like a lot of work! not to worry, get covered has done that for you. when you obtain a quote, you will see the company ratings and other necessary details to make the best choice for you and your family.`,
    },
    {
      title: "How is the price of Life Insurance decided?",
      content: `The premium price depends on many factors, and its calculation is complex, but to sum it up, the insured age, gender, health condition, smoking status, and lifestyle are the main factors that life insurance companies will consider assessing the risk of insuring someone. You also must know that the cost of insurance might be different than the premium price, depending on the life insurance product you choose.`,
    },
    {
      title: "Can Life insurance claims get declined?",
      content: `Yes! But only if you submit your application in bad faith. Life insurance is a contract between the policy owner and the insurance company. In many cases, insurance companies ask questions and, based on the insured answers, offer insurance coverage. Therefore, if you answer the questions truthfully, your claim will not be declined since the insurance company must honour the contract in a highly regulated industry.`
    },
    {
      title: "How does Get Covered’s policy recommendation works?",
      content: `Get Covered was built based on years of experience with insurance experts, and with the help of advanced technology, it can guide anyone to find out how much coverage amount and what policy type suits their needs the most. Using concepts like KYC, and financial analytics, Get Covered produces a custom insurance solution for its users.`
    },
    {
      title: "How can I choose a good Insurance Company?",
      content: `Choosing a insurance company is simple yet crucial. Each company you have approached for a quote has probably sent you a different price, and you are now faced with one to choose from. Some are more well-recognized but expensive, while others offer a more affordable choice. You should check the financial health, payout ratio and years of operation of the company and take into consideration their overall rating. I sound like a lot of work! Not to worry, Get Covered has done that for you. When you obtain a quote, you will see the company ratings and other necessary details to make the best choice for you and your family.`
    }
  ];
  return (
    <>
      <Desktop>
        {" "}
        <div
          className=" w-100 flex flex-column justifiy-content-center "
          style={{
            borderBottom: "1px solid #E7E7E7",
            paddingBottom: "150px",
          }}
        >
          <div
            style={{
              display: " flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3 className="heading-landing-gold "> {t("FAQ.title")}</h3>
            <h3
              className="heading-landing-blue "
              style={{ marginTop: "-10px" }}
            >
              {t("FAQ.sub_title")}
            </h3>
            {/* <h3
              className="text-landing "
              style={{ marginTop: "-10px", maxWidth: " 60%" }}
            >
              {t("FAQ.describe")}
            </h3> */}
          </div>
          <div>
            <div className="mt100 w-100 flex flex-column justifiy-content-center">
              <div className="accordion">
                {accordionData.map(({ title, content }) => (
                  <Accordion title={title} content={content} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Desktop>
      <Tablet>
        {" "}
        <div
          className=" w-100 flex flex-column justifiy-content-center px-4"
          style={{
            borderBottom: "1px solid #E7E7E7",
            paddingBottom: "9em",
          }}
        >
          <div
            style={{
              display: " flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3 className="heading-landing-gold "> {t("FAQ.title")}</h3>
            <h3
              className="heading-landing-blue "
              style={{ marginTop: "-10px" }}
            >
              {t("FAQ.sub_title")}
            </h3>
            {/* <h3 className="text-landing " style={{ marginTop: "-5px" }}>
              {t("FAQ.describe")}
            </h3> */}
          </div>
          <div>
            <div className="mt-5 w-100 flex flex-column justifiy-content-center">
              <div className="accordion">
                {accordionData.map(({ title, content }) => (
                  <Accordion title={title} content={content} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Tablet>
    </>
  );
}
