import React, { Suspense, useEffect, useState } from "react";

import { Grid, Image } from "semantic-ui-react";
import Loading from "../../components/Loading/LoadingOld";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
const CompanySlider = React.lazy(() =>
  import("../../components/CompanySlider/CompanySlider")
);
export default function OurCustomers() {
  const { t } = useTranslation();
  const [sliderLoad, setSliderLoad] = useState(1);
  
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <div
          className="mt100 w-100 flex d-flex justifiy-content-end "
          style={{
            paddingTop: "1.5rem",
            borderBottom: "1px solid #E7E7E7",
            paddingBottom: "70px", 
            marginTop: '19.5%'
          }}
        >
          <div
            style={{
              maxWidth: " 600px",
            }}
          >
            <h3 className="heading-landing-gold">
              {" "}
              {t("our_customers_li.title")}
            </h3>
            <h3
              className="heading-landing-blue pr-3"
              style={{ marginTop: "-10px" }}
            >
              {t("our_customers_li.sub_title")}
            </h3>
            <h3
              className="text-landing pr-3"
              style={{ marginTop: "-10px", maxWidth: " 600px" }}
            >
              {t("our_customers_li.describe")}
            </h3>
          </div>

          <div
            style={{
              width: "60%",
              padding: "0px 0px",

              marginLeft: "2em",
            }}
          >
            <CompanySlider /> {/* <Suspense fallback={<Loading />}> */}
            {/* {sliderLoad > 0 && <CompanySlider />}
            {/* </Suspense> */}
            {/*  {sliderLoad === 0 && (
              <div className="py-2">
                <Loading />
              </div>
            )} */}
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div className=" w-100 flex flex-column justifiy-content-center px-4 py-4 div-customer"

        >
          <div>
            <h3 className="heading-landing-gold">
              {" "}
              {t("our_customers_li.title")}
            </h3>
            <h3
              className="heading-landing-blue "
              style={{ marginTop: "-10px" }}
            >
              {t("our_customers_li.sub_title")}
            </h3>
            <h3
              className="text-landing "
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              {t("our_customers_li.describe")}
            </h3>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            {" "}
            <Suspense fallback={<Loading />}>
              {sliderLoad > 0 && <CompanySlider />}
            </Suspense>
            {sliderLoad === 0 && (
              <div className="py-2">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </Tablet>
    </>
  );
}
