import React, { Suspense } from "react";
import { HashRouter } from "react-router-dom";
import { Routes } from "./app/router/Routes";
import axios from "axios";

import { default as config } from "./app/constants/config.json";

import "./app/locale";
import { storeGet } from "./app/crud/storage";
import Loading from "./app/components/Loading/splashLoading";

axios.defaults.baseURL = config[process.env.NODE_ENV].apiBaseURL;
const userStorage = storeGet("user");
if (userStorage && userStorage.token) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${userStorage.token}`;
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
}

function App() { 
  return (
    <Suspense
      fallback={
        <div
          id="splashScreen"
          style={{ backgroundColor: "var(--main-color)", height: "100vh" }}
        >
          <Loading />
        </div>
      }
    >
      <HashRouter>
        <Routes />
      </HashRouter>
    </Suspense>
  );
}

export default App;



// live url http://1.6.98.141:2023/
// local
// "development": {
//   "apiBaseURL" :"http://192.168.3.121/shivam/get_covered_backend/public/api/" ,  http://1.6.98.141/Mobile/get_covered/public/api/
//   "backendFileBaseUrl": "https://panel.apluswealth.com/storage/"
// }
// live
// "development": {
//     "apiBaseURL": "http://1.6.98.141/Mobile/get_covered/public/api/",
//     "backendFileBaseUrl": "https://panel.apluswealth.com/storage/"
//   }

// "development": {
//   "apiBaseURL": "https://panel.apluswealth.com/api/",
//   "backendFileBaseUrl": "https://panel.apluswealth.com/api/"
// },
// "production": {
// "apiBaseURL": "https://panel.apluswealth.com/api/",
// "backendFileBaseUrl": "https://panel.apluswealth.com/api/"
// },