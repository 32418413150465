import React, { useState } from "react";
import { Transition } from "semantic-ui-react";
const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div
          className="title_page_according"
          style={{ width: "90%", textAlign: "left" }}
        >
          {title}
        </div>
        <div style={{ alignSelf: "flex-start", top: "0px", fontSize: "32px" }}>
          {isActive ? (
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: " rotate(0deg )",
                transition: "transform 800ms ease",
              }}
            >
              <path
                d="M0.964005 5.5H8.0373C8.16242 5.49945 8.28458 5.46027 8.38833 5.38739C8.49208 5.31452 8.57275 5.21123 8.62016 5.09058C8.66756 4.96994 8.67956 4.83737 8.65465 4.70962C8.62973 4.58187 8.56901 4.46469 8.48017 4.37289L4.94985 0.694968C4.89103 0.633189 4.82106 0.584154 4.74396 0.550691C4.66687 0.517228 4.58417 0.5 4.50065 0.5C4.41713 0.5 4.33444 0.517228 4.25734 0.550691C4.18024 0.584154 4.11027 0.633189 4.05145 0.694968L0.521134 4.37289C0.432293 4.46469 0.371575 4.58187 0.346657 4.70962C0.321739 4.83737 0.33374 4.96994 0.381144 5.09058C0.428547 5.21123 0.509224 5.31452 0.612972 5.38739C0.71672 5.46027 0.83888 5.49945 0.964005 5.5Z"
                fill="#105399"
              />
            </svg>
          ) : (
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: " rotate( -180deg )",
                transition: "transform 800ms ease",
              }}
            >
              <path
                d="M0.964005 5.5H8.0373C8.16242 5.49945 8.28458 5.46027 8.38833 5.38739C8.49208 5.31452 8.57275 5.21123 8.62016 5.09058C8.66756 4.96994 8.67956 4.83737 8.65465 4.70962C8.62973 4.58187 8.56901 4.46469 8.48017 4.37289L4.94985 0.694968C4.89103 0.633189 4.82106 0.584154 4.74396 0.550691C4.66687 0.517228 4.58417 0.5 4.50065 0.5C4.41713 0.5 4.33444 0.517228 4.25734 0.550691C4.18024 0.584154 4.11027 0.633189 4.05145 0.694968L0.521134 4.37289C0.432293 4.46469 0.371575 4.58187 0.346657 4.70962C0.321739 4.83737 0.33374 4.96994 0.381144 5.09058C0.428547 5.21123 0.509224 5.31452 0.612972 5.38739C0.71672 5.46027 0.83888 5.49945 0.964005 5.5Z"
                fill="#4F4F4F"
              />
            </svg>
          )}
        </div>
      </div>

      <Transition visible={isActive} animation="fade down" duration={800}>
        <div className="accordion-content">
          <h3
            className="text-landing text-left"
            style={{ whiteSpace: "pre-line" }}
          >
            {content}
          </h3>
        </div>
      </Transition>
    </div>
  );
};

export default Accordion;
