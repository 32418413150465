import React from "react";
import { Container, Grid, Divider } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import Layout from "../../layout/Layout";
import ScrollToTop from "../../components/Tools/ScrollToTop";
// import "../../styles/icon/svg-sprite.svg"

export default function Index() {
  const { t } = useTranslation();
   // console.log('landing 6');

  return (
    <>
      <ScrollToTop />
      <Layout>
        <div
          style={{
            position: "relative",
            paddingBottom: "1rem",
          }}
        >
          <Grid className="mx-0 mt-3 lnding_grid_mobile w-100 justify-content-center flex ">
            <Grid.Row>
              <Grid.Column
                className="w-100 justify-content-center flex "
                computer={16}
                tablet={16}
                mobile={16}
                style={{
                  padding: "0px",

                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="landing_logo "
                  src="./assets/img/logo.png"
                  alt="logo"
                  style={{
                    width: "200px",
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="w-100 justify-content-center flex flex-column mt-5 py-5 landing-page">
            <h1 className="landing_title">{t("privacyPolicy.title")}</h1>

            <p className="landing_subtitle">
              <b>{t("privacyPolicy.sub_stitle")}</b>
            </p>
          </div>
          <Container as="main" className="term-container">
            <div className="termCard  ">
              <p className=" text-left text-pre-wrap ">
                {t("privacyPolicy.describe")}
              </p>
            </div>

            <div className="w-100 justify-content-center flex flex-column mt-5 py-5 landing-page">
              <p className="  landing_title">{t("privacyPolicy.header_1")}</p>
            </div>
            {/* Interpretation */}
            <div className="w-100 justify-content-center flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.sub_title_1")}
              </p>
              <div className="termCard text-left ">
                <p className="text-pre-wrap ">
                  {t("privacyPolicy.describe_1")}
                </p>
              </div>
            </div>
            {/* Definitions */}

            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.sub_title_2")}
              </p>
              <div className="termCard  ">
                <p className=" text-left   text-pre-wrap ">
                  {t("privacyPolicy.describe_2_1_details")}
                </p>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                  <p className="  text-left  text-pre-wrap ">
                    <b>{t(`privacyPolicy.describe_2_${item}_sub`)}</b>
                    {t(`privacyPolicy.describe_2_${item}_detail`)}
                    {item === 5 && (
                      <a
                        href="Http://www.GetCovered.apluswealth.com"
                        id="privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "#105399",
                          textDecoration: "underline",
                        }}
                      >
                        {" "}
                        Http://www.GetCovered.apluswealth.com
                      </a>
                    )}
                  </p>
                ))}
              </div>
            </div>

            {/* Collecting and Using Your Personal Data */}
            <div className="w-100 justify-content-center flex flex-column mt-5 py-5 landing-page">
              <p className="  landing_title">{t("privacyPolicy.header_2")}</p>
            </div>
            <p className="Policy-landing-gold ">
              {t("privacyPolicy.sub_title_2_1")}
            </p>
            {/* Personal Data */}
            <div className="w-100 justify-content-start flex flex-column pb-5 ">
              <p className=" heading-landing-blue">
                {t("privacyPolicy.sub_title_2_2")}
              </p>
              <div className="termCard  ">
                <p className=" text-left   text-pre-wrap ">
                  {t("privacyPolicy.describe_2_2_details")}
                </p>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <p className="  text-left  text-pre-wrap ">
                    {t(`privacyPolicy.describe_2_2_${item}`)}
                  </p>
                ))}
              </div>
            </div>

            {/* Personal Data */}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_3")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_3_1`)}
                </p>
              </div>
            </div>
            {/* Tracking Technologies and Cookies*/}
            <div className="w-100 justify-content-start flex flex-column pb-5 ">
              <p className=" heading-landing-blue">
                {t("privacyPolicy.header_4")}
              </p>
              <div className="termCard  ">
                <p className=" text-left   text-pre-wrap ">
                  {t("privacyPolicy.describe_4_1")}
                </p>
                <p className=" text-left   text-pre-wrap ">
                  {t("privacyPolicy.describe_4_detail")}
                </p>
                {[1, 2, 3].map((item) => (
                  <p className="  text-left  text-pre-wrap ">
                    <b>{t(`privacyPolicy.describe_4_${item}_sub`)}</b>
                    {t(`privacyPolicy.describe_4_${item}_detail`)}
                  </p>
                ))}
                <p className=" text-left   text-pre-wrap ">
                  {t("privacyPolicy.describe_4_end")}
                </p>
              </div>
            </div>

            {/* Use of Your Personal Data */}

            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_5")}
              </p>
              <div className="termCard  ">
                <p className=" text-left   text-pre-wrap ">
                  {t("privacyPolicy.describe_5_1")}
                  <a
                    href="www.getsmarteraboutmoney.ca"
                    id="privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#105399",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    www.getsmarteraboutmoney.ca
                  </a>
                  .
                </p>
                <p className="text-left  text-pre-wrap ">
                  {t("privacyPolicy.describe_5_1_details")}
                </p>
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <p className="  text-left  text-pre-wrap ">
                    <b>{t(`privacyPolicy.describe_5_${item}_sub`)}</b>
                    {t(`privacyPolicy.describe_5_${item}_detail`)}
                  </p>
                ))}
                <p className="text-left  text-pre-wrap ">
                  {t("privacyPolicy.describe_5_2_details")}
                </p>
                {[1, 2, 3, 4, 5].map((item) => (
                  <p className="  text-left  text-pre-wrap ">
                    <b>{t(`privacyPolicy.describe_5_2_${item}_sub`)}</b>
                    {t(`privacyPolicy.describe_5_2_${item}_detail`)}
                  </p>
                ))}
              </div>
            </div>

            {/* Retention of Your Personal Data*/}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_6")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_6_1`)}
                </p>
              </div>
            </div>

            {/* Transfer of Your Personal Data*/}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_7")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_7_1`)}
                </p>
              </div>
            </div>

            {/* Disclosure of Your Personal Data */}
            <div className="w-100 justify-content-center flex flex-column mt-5 py-5 landing-page">
              <p className="  landing_title">{t("privacyPolicy.header_8")}</p>
            </div>
            {/* Business Transactions*/}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_9")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_9_1`)}
                </p>
              </div>
            </div>
            {/* Business Transactions*/}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_10")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_10_1`)}
                </p>
              </div>
            </div>

            {/* Other legal requirements */}
            <div className="w-100 justify-content-start flex flex-column pb-5 ">
              <p className=" heading-landing-blue">
                {t("privacyPolicy.header_11")}
              </p>
              <div className="termCard  ">
                <p className=" text-left   text-pre-wrap ">
                  {t("privacyPolicy.describe_11_1")}
                </p>
                {[1, 2, 3, 4, 5].map((item) => (
                  <p className="  text-left  text-pre-wrap ">
                    {t(`privacyPolicy.describe_11_1_${item}`)}
                  </p>
                ))}
              </div>
            </div>

            {/* Security of Your Personal Data"*/}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_12")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_12_1`)}
                </p>
              </div>
            </div>
            {/* Children’s Privacy"*/}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_13")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_13_1`)}
                </p>
              </div>
            </div>
            {/* Links to Other Websites"*/}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_14")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_14_1`)}
                </p>
              </div>
            </div>
            {/* "Changes to this Privacy Policy"*/}
            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_15")}
              </p>
              <div className="termCard  ">
                <p className="  text-left  text-pre-wrap ">
                  {t(`privacyPolicy.describe_15_1`)}
                </p>
              </div>
            </div>

            {/* Contact Us */}

            <div className="w-100 justify-content-start flex flex-column py-5 ">
              <p className="heading-landing-blue ">
                {t("privacyPolicy.header_16")}
              </p>
              <div className="termCard  ">
                <p className=" text-left   text-pre-wrap ">
                  {t("privacyPolicy.describe_16_1")}
                </p>
                {[1, 2, 3].map((item) => (
                  <p className="  text-left  text-pre-wrap ">
                    {t(`privacyPolicy.describe_16_1_${item}`)}

                    {item === 2 && (
                      <a
                        href="http://www.getcovered.apluswealth.com"
                        id="privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "#105399",
                          textDecoration: "underline",
                        }}
                      >
                        {" "}
                        http://www.getcovered.apluswealth.com
                      </a>
                    )}
                  </p>
                ))}
              </div>
            </div>
            <p className=" heading-landing-gold">{t("privacyPolicy.date")}</p>
            <Divider className="w-100" />
          </Container>
        </div>
      </Layout>
    </>
  );
}
