// import React from "react";
// import { Container, Grid } from "semantic-ui-react";

// import { useTranslation } from "react-i18next";
// import { NavLink, useHistory } from "react-router-dom";

// import Layout from "../../layout/Layout";
// import FAQ from "./FAQ.js";
// import ApplicationFeatures from "./ApplicationFeatures";
// import Baner from "./Baner";
// import OurCustomers from "./OurCustomers";
// import OurServices from "./OurServices";
// import Testimonials from "./Testimonials.js";
// import OurFeatures from "./OurFeatures";
// import WhyUs from "./WhyUs.js";
// import { useMediaQuery } from "react-responsive";
// import ScrollToTop from "../../components/Tools/ScrollToTop";
// import "./dihome.css";
// import "../Home/Home.css";
// import appimages from "../../assets/img";
// import "./disabilityLanding.css";

// export default function Home() {
//   const history = useHistory();
//   const { t } = useTranslation();

//   const Desktop = ({ children }) => {
//     const isDesktop = useMediaQuery({ minWidth: 1080 });
//     return isDesktop ? children : null;
//   };
//   const Tablet = ({ children }) => {
//     const isTablet = useMediaQuery({ maxWidth: 1079 });
//     return isTablet ? children : null;
//   };
//   return (
//     <>
//       <ScrollToTop />
//       <Layout>
//         <Grid className="mx-0 mt-3 disability-lnding-grid-mobile lnding_grid_mobile w-100 jusfity-content-center flex ">
//           <Grid.Row>
//             <Grid.Column
//               className="w-100 jusfity-content-center flex "
//               computer={16}
//               tablet={16}
//               mobile={16}
//               style={{
//                 padding: "0px",

//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 className="landing_logo "
//                 src="./assets/img/logo.png"
//                 alt="logo"
//                 style={{
//                   width: "260px",
//                 }}
//               />
//             </Grid.Column>
//           </Grid.Row>
//         </Grid>

//         <Container
//           as="main"
//           className="py-4 lnding_grid_main lifeInsurance-background-image"
//         >
//           <Desktop>
//             <div
//               className="hero-section"
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//               }}
//             >
//               <div className={"upper-div"}>
//                 <div className="life_landing_image">
//                   {/**Image */}
//                   <div
//                     className="life-img"
//                     style={{
//                       background: `url(${appimages?.di})  no-repeat top center`,
//                       backgroundSize: "contain",
//                     }}
//                   >
//                     {""}
//                   </div>
//                 </div>

//                 <div
//                   className="flex-wrapper"
//                   style={{
//                     display: "flex",
//                     flex: 1,
//                     flexDirection: "column",
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       margin: "20px 0px",
//                     }}
//                   >
//                     <h3
//                       style={{
//                         margin: 0,
//                       }}
//                       className="life_title1_heading"
//                     >
//                       {"GetCovered: Your virtual"}
//                     </h3>
//                     <h3
//                       className="life_title1_heading"
//                       style={{
//                         margin: 0,
//                       }}
//                     >
//                       {"disability insurance advisor"}
//                     </h3>

//                     <h4
//                       style={{
//                         margin: "10px 0 0",
//                         // padding : '0px 35px',
//                         width: "inherit",
//                       }}
//                       className="life_title1_sub_heading"
//                     >
//                       {"Let's find the disability policy that fits your"}
//                     </h4>
//                     <h4
//                       className="life_title1_sub_heading section-one-desc-line-2"
//                       style={{
//                         margin: 0,
//                         // padding: "0px 14px",
//                         width: "max-content",
//                       }}
//                     >
//                       {"financial objectives and best suits your occupation."}
//                     </h4>
//                   </div>

//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       // margin: "20px 5px",
//                       margin: "30px 5px 20px 5px",
//                     }}
//                   >
//                     <h3 className="life_title1_heading">
//                       {"Disability Insurance Quote"}
//                     </h3>

//                     <h4 className="life_title1_sub_heading">
//                       {"In the event of an injury or illness, ensure your"}
//                     </h4>
//                     <h4
//                       className="life_title1_sub_heading"
//                       style={{
//                         marginTop: "-15px",
//                       }}
//                     >
//                       {"financial security with disability insurance."}
//                     </h4>
//                   </div>

//                   <div
//                     className="flex-right-side lets-begin-section"
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-evenly",
//                       // margin: "20px 10px",
//                       margin: "37px 10px 20px 10px",
//                     }}
//                   >
//                     <button
//                       onClick={() => history.push("quote/DI/IamFamiliar")}
//                       className="life_buttons"
//                       title="Get Instant Quote"
//                     >
//                       {"Let's Begin"}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Desktop>
//           <Tablet>
//             <div
//               className="hero-section"
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//               }}
//             >
//               <div className={"upper-div"}>
//                 <div className="life_landing_image">
//                   {/**Image */}
//                   <div
//                     className="life-img"
//                     style={{
//                       background: `url(${appimages?.life})  no-repeat top center`,
//                       backgroundSize: "contain",
//                     }}
//                   >
//                     {""}
//                   </div>
//                 </div>

//                 <div
//                   className="flex-wrapper"
//                   style={{
//                     display: "flex",
//                     flex: 1,
//                     flexDirection: "column",
//                   }}
//                 >
//                   <div
//                     className="first-section"
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       margin: "20px 0px",
//                     }}
//                   >
//                     <h3
//                       style={{
//                         margin: 0,
//                       }}
//                       className="life_title1_heading"
//                     >
//                       {"GetCovered: Your virtual"}
//                     </h3>
//                     <h3
//                       className="life_title1_heading"
//                       style={{
//                         margin: 0,
//                       }}
//                     >
//                       {"disability insurance advisor"}
//                     </h3>

//                     <h4
//                       style={{
//                         margin: "10px 0 0",
//                         // padding : '0px 35px',
//                         width: "inherit",
//                       }}
//                       className="life_title1_sub_heading"
//                     >
//                       {"Let's find the disability policy that fits your"}
//                     </h4>
//                     <h4
//                       className="life_title1_sub_heading section-one-desc-line-2"
//                       style={{
//                         margin: 0,
//                         // padding: "0px 14px",
//                         // width : 'max-content'
//                       }}
//                     >
//                       {"financial objectives and best suits your occupation."}
//                     </h4>
//                   </div>

//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       // margin: "20px 5px",
//                       margin: "30px 5px 20px 5px",
//                     }}
//                   >
//                     <h3 className="life_title1_heading">
//                       {"Disability Insurance Quote"}
//                     </h3>

//                     <h4 className="life_title1_sub_heading">
//                       {"In the event of an injury or illness, ensure your"}
//                     </h4>
//                     <h4
//                       className="life_title1_sub_heading"
//                       style={{
//                         marginTop: "-15px",
//                       }}
//                     >
//                       {"financial security with disability insurance."}
//                     </h4>
//                   </div>

//                   <div
//                     className="flex-right-side lets-begin-section"
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-evenly",
//                       // margin: "20px 10px",
//                       margin: "37px 10px 20px 10px",
//                     }}
//                   >
//                     <button
//                       onClick={() => history.push("quote/DI/IamFamiliar")}
//                       className="life_buttons"
//                       title="Get Instant Quote"
//                     >
//                       {"Let's Begin"}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Tablet>
//         </Container>
//         <img
//           src="./assets/img/home/landingpage.svg"
//           class="mountain"
//           width={"100%"}
//           height={"100%"}
//         />

//         <Container as="main" className="container_main1 di-landing">
//           <OurCustomers cust_describe={t("our_customers_di.describe")} />
//           <OurFeatures
//             feature={{
//               des: t("our_features_di.describe"),
//               g1: t("our_features_di.grid1"),
//               g2: t("our_features_di.grid2"),
//               g3: t("our_features_di.grid3"),
//               g4: t("our_features_di.grid4"),
//             }}
//           />
//           <ApplicationFeatures
//             features={{
//               sub_title: t("Home_application_features_di.sub_title"),
//               gd: t("Home_application_features_di.grid1_desc"),
//               gd2: t("Home_application_features_di.grid2_desc"),
//               gd3: t("Home_application_features_ci.grid3_desc"),
//             }}
//           />
//           <OurServices />
//           <WhyUs
//             features={{
//               des: t("why_aplus_di.describe"),
//             }}
//           />
//           <Testimonials />
//         </Container>
//         <Baner
//           feature={{
//             des: t("banner_di.describe"),
//           }}
//         />
//         <Container
//           as="main"
//           className=" container_main2"
//           style={{ marginTop: "4.5rem" }}
//         >
//           <FAQ />
//         </Container>
//       </Layout>
//     </>
//   );
// }

// import React from "react";
// import { Container, Grid } from "semantic-ui-react";

// import { useTranslation } from "react-i18next";
// import { NavLink, useHistory } from "react-router-dom";

// import Layout from "../../layout/Layout";
// import FAQ from "./FAQ.js";
// import ApplicationFeatures from "./ApplicationFeatures";
// import Baner from "./Baner";
// import OurCustomers from "./OurCustomers";
// import OurServices from "./OurServices";
// import Testimonials from "./Testimonials.js";
// import OurFeatures from "./OurFeatures";
// import WhyUs from "./WhyUs.js";
// import { useMediaQuery } from "react-responsive";
// import ScrollToTop from "../../components/Tools/ScrollToTop";
// import "./home.css";
// import "./ciLanding.css";
// import "../Home/Home.css";
// import "../Home/Landing.css";
// import appimages from "../../assets/img";

// export default function Home() {
//   const { t } = useTranslation();
//   const history = useHistory();

//   const Desktop = ({ children }) => {
//     const isDesktop = useMediaQuery({ minWidth: 1080 });
//     return isDesktop ? children : null;
//   };
//   const Tablet = ({ children }) => {
//     const isTablet = useMediaQuery({ maxWidth: 1079 });
//     return isTablet ? children : null;
//   };
//   return (
//     <>
//       <ScrollToTop />
//       <Layout>
//         <Grid className="mx-0 mt-3 critical-lnding-grid-mobile lnding_grid_mobile w-100 jusfity-content-center flex ">
//           <Grid.Row>
//             <Grid.Column
//               className="w-100 jusfity-content-center flex "
//               computer={16}
//               tablet={16}
//               mobile={16}
//               style={{
//                 padding: "0px",

//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 className="landing_logo "
//                 src="./assets/img/logo.png"
//                 alt="logo"
//                 style={{
//                   width: "260px",
//                 }}
//               />
//             </Grid.Column>
//           </Grid.Row>
//         </Grid>

// import "./ciLanding.css";
//         <Container
//           as="main"
//           className="py-4 lnding_grid_main  lifeInsurance-background-image"
//         >
//           <Desktop>
//             <div
//               className="hero-section"
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//               }}
//             >
//               <div className={"upper-div"}>
//                 <div className="life_landing_image">
//                   <div
//                     className="life-img"
//                     style={{
//                       background: `url(${appimages?.ci})  no-repeat top center`,
//                       backgroundSize: "contain",
//                     }}
//                   >
//                     {""}
//                   </div>
//                 </div>

//                 <div
//                   className="flex-wrapper"
//                   style={{
//                     display: "flex",
//                     flex: 1,
//                     flexDirection: "column",
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       margin: "20px 0px",
//                     }}
//                   >
//                     <h3 className="life_title1_heading first-section-first-header">
//                       {"GetCovered: Your virtual critical"}
//                     </h3>
//                     <h3 className="life_title1_heading first-section-second-header">
//                       {"illness insurance advisor"}
//                     </h3>
//                   </div>
//                   <div
//                     style={{
//                       margin: "-16px 0 0 25px",
//                       width: "104%",
//                     }}
//                     className="first-section-desc-wrapper"
//                   >
//                     <h4
//                       style={{
//                         margin: "10px 0 0",
//                         padding: "0px 8px",
//                       }}
//                       className="life_title1_sub_heading first-section-desc-wrapper-first-line"
//                     >
//                       {"Find your policy instantly by comparing different"}
//                     </h4>

//                     <h4
//                       className="life_title1_sub_heading first-section-desc-wrapper-second-line"
//                       style={{
//                         margin: "0 0 0 -15px",
//                         padding: "0px 8px",
//                         width: "107%",
//                       }}
//                     >
//                       {"products of the top Canadian insurance companies."}
//                     </h4>
//                   </div>

//                   <div
//                     className="sec-para-wrapper"
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       // margin: "20px 5px",
//                       // margin: "69px 5px 50px 5px",
//                       margin: "40px 5px 50px",
//                       marginLeft: "10%",
//                     }}
//                   >
//                     <h3 className="life_title1_heading sec-section-header">
//                       {"Critical Illness Insurance Quote"}
//                     </h3>
//                     <div
//                       className="sec-para-desc-wrapper"
//                       style={{
//                         // margin : '10px 0 0 47px'
//                         margin: "10px 0 0 30px",
//                       }}
//                     >
//                       <h4
//                         className="life_title1_sub_heading mx-cn"
//                         style={{
//                           width: "100%",
//                         }}
//                       >
//                         {
//                           "Don't let a critical illness derail your financial stability."
//                         }
//                       </h4>
//                       <h4
//                         className="life_title1_sub_heading mx-cn"
//                         style={{
//                           width: "100%",
//                           marginTop: "-12px",
//                         }}
//                       >
//                         {
//                           "Protect yourself with proper critical illness insurance."
//                         }
//                       </h4>
//                     </div>
//                   </div>
//                   <div
//                     className="flex-right-side lets-begin-section"
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-evenly",
//                       margin: "20px 10px",
//                       // marginLeft : '60px'
//                       margin: "10px 10px 20px 60px",
//                     }}
//                   >
//                     <button
//                       onClick={() => history.push("quote/CI/IamFamiliar")}
//                       className="life_buttons"
//                       title="Get Instant Quote"
//                     >
//                       {"Let's Begin"}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Desktop>
//           <Tablet>
//             <div
//               className="hero-section"
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//               }}
//             >
//               <div className={"upper-div"}>
//                 <div className="life_landing_image">
//                   {/**Image */}
//                   <div
//                     className="life-img"
//                     style={{
//                       background: `url(${appimages?.life})  no-repeat top center`,
//                       backgroundSize: "contain",
//                     }}
//                   >
//                     {""}
//                   </div>
//                 </div>

//                 <div
//                   className="flex-wrapper"
//                   style={{
//                     display: "flex",
//                     flex: 1,
//                     flexDirection: "column",
//                   }}
//                 >
//                   <div className="first-section">
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "column",
//                         margin: "20px 0px",
//                       }}
//                     >
//                       <h3
//                         style={{
//                           margin: 0,
//                           // width: "110%",
//                         }}
//                         className="life_title1_heading  first-section-first-header"
//                       >
//                         {"GetCovered: Your virtual critical"}
//                       </h3>
//                       <h3
//                         className="life_title1_heading first-section-second-header"
//                         style={
//                           {
//                             // margin: "0 0 0 50px",
//                           }
//                         }
//                       >
//                         {"illness insurance advisor"}
//                       </h3>
//                     </div>
//                     <div className="first-section-desc-wrapper">
//                       <h4 className="life_title1_sub_heading first-section-desc-wrapper-first-line">
//                         {"Find your policy instantly by comparing different"}
//                       </h4>

//                       <h4 className="life_title1_sub_heading first-section-desc-wrapper-second-line">
//                         {"products of the top Canadian insurance companies."}
//                       </h4>
//                     </div>
//                   </div>

//                   <div
//                     className="sec-para-wrapper"
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       // margin: "20px 5px",
//                       // margin: "69px 5px 50px 5px",
//                       margin: "40px 5px 50px",
//                     }}
//                   >
//                     <h3
//                       className="life_title1_heading"
//                       style={
//                         {
//                           // width: "108%",
//                         }
//                       }
//                     >
//                       {"Critical Illness Insurance Quote"}
//                     </h3>
//                     <div className="sec-para-desc-wrapper">
//                       <h4 className="life_title1_sub_heading mx-cn">
//                         {
//                           "Don't let a critical illness derail your financial stability."
//                         }
//                       </h4>
//                       <h4
//                         className="life_title1_sub_heading mx-cn"
//                         style={{
//                           marginTop: "-12px",
//                         }}
//                       >
//                         {
//                           "Protect yourself with proper critical illness insurance."
//                         }
//                       </h4>
//                     </div>
//                   </div>
//                   <div
//                     className="flex-right-side lets-begin-section"
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-evenly",
//                     }}
//                   >
//                     <button
//                       onClick={() => history.push("quote/CI/IamFamiliar")}
//                       className="life_buttons"
//                       title="Get Instant Quote"
//                     >
//                       {"Let's Begin"}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Tablet>
//         </Container>
//         <img
//           src="./assets/img/home/landingpage.svg"
//           width={"100%"}
//           height={"100%"}
//           className="mountain"
//         />

//         <Container as="main" className="container_main1 ci-landing">
//           <OurCustomers cust_describe={t("our_customers_ci.describe")} />
//           <OurFeatures
//             feature={{
//               g1: t("our_features_ci.grid1"),
//               g2: t("our_features_ci.grid2"),
//               g3: t("our_features_ci.grid3"),
//               g4: t("our_features_ci.grid4"),
//             }}
//           />
//           <ApplicationFeatures
//             features={{
//               sub_title: t("Home_application_features_ci.sub_title"),
//               des: t("Home_application_features_ci.describe"),
//               gd: t("Home_application_features_ci.grid1_desc"),
//               gd2: t("Home_application_features_ci.grid2_desc"),
//               gd3: t("Home_application_features_ci.grid3_desc"),
//             }}
//           />
//           <OurServices />
//           <WhyUs
//             features={{
//               des: t("why_aplus_ci.describe"),
//             }}
//           />
//           <Testimonials />
//         </Container>
//         <Baner
//           feature={{
//             des: t("banner_di.describe"),
//           }}
//         />
//         <Container
//           as="main"
//           className=" container_main2"
//           style={{ marginTop: "4.5rem" }}
//         >
//           <FAQ />
//         </Container>
//       </Layout>
//     </>
//   );
// }

// import React from "react";
// import { Container, Grid, Embed } from "semantic-ui-react";
// import { useTranslation } from "react-i18next";
// import { NavLink, useHistory } from "react-router-dom";
// import Layout from "../../layout/Layout";
// import FAQ from "./FAQ.js";
// import ApplicationFeatures from "./ApplicationFeatures";
// import Baner from "./Baner";
// import OurCustomers from "./OurCustomers";
// import OurServices from "./OurServices";
// import Testimonials from "./Testimonials.js";
// import OurFeatures from "./OurFeatures";
// import WhyUs from "./WhyUs.js";
// import { useMediaQuery } from "react-responsive";
// import ScrollToTop from "../../components/Tools/ScrollToTop";
// import './Lifelanding.css'
// import './home.css'
// import "../Home/Home.css";
// import "../Home/Landing.css";
// import appimages from "../../assets/img";

// export default function Home() {
//   const { t } = useTranslation();
//   const history = useHistory()

//   const Desktop = ({ children }) => {
//     const isDesktop = useMediaQuery({ minWidth: 1080 });
//     return isDesktop ? children : null;
//   };
//   const Tablet = ({ children }) => {
//     const isTablet = useMediaQuery({ maxWidth: 1079 });
//     return isTablet ? children : null;
//   };
//    // console.log('landing 4');

//   return (
//     <>
//       <ScrollToTop />
//       <Layout>
//       <Grid className="mx-0 mt-3 life-lnding-grid-mobile lnding_grid_mobile w-100 jusfity-content-center flex ">
//           <Grid.Row>
//             <Grid.Column
//               className="w-100 jusfity-content-center flex "
//               computer={16}
//               tablet={16}
//               mobile={16}
//               style={{
//                 padding: "0px",
//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 className="landing_logo "
//                 src="./assets/img/logo.png"
//                 alt="logo"
//                 style={{
//                   width: "260px",
//                 }}
//               />
//             </Grid.Column>
//           </Grid.Row>
//       </Grid>
//          <Container
//           as="main"
//           className="py-4 lnding_grid_main landing_main_container lifeInsurance-background-image"
//         >
//            <Desktop>
//             <div
//               className="hero-section"
//               style={{
//                 display: "flex" ,
//                 flexDirection : 'column'
//               }}
//             >
//               <div
//                className={'upper-div'}
//               >
//                 <div
//                   className="life_landing_image"
//                 >
//                   {/**Image */}
//                   <div className="life-img"
//                     style={{
//                       background : `url(${appimages?.life})  no-repeat top center` ,
//                       backgroundSize: "contain"
//                     }}
//                   >
//                     {''}

//                   </div>
//                 </div>

//                 <div
//                   className="flex-wrapper"
//                   style={{
//                     display: 'flex',
//                     flex: 1,
//                     flexDirection: 'column'
//                   }}
//                 >
//                   <div
//                   className="flex-right-side first-section"
//                   style={{
//                       display: 'flex',
//                       flexDirection: "column",
//                       margin: "20px 0px",
//                     }}
//                   >
//                     <h3
//                     style={{
//                       margin : 0
//                     }}
//                       className="life_title1_heading"
//                     >{'GetCovered: Your virtual'}</h3>
//                     <h3
//                       className="life_title1_heading"
//                       style={{
//                         margin : 0
//                       }}
//                     >{'life insurance advisor'}</h3>

//                     <h4
//                       style={{
//                       margin : '10px 0 0'
//                     }}
//                       className="life_title1_sub_heading"
//                     >{'Reliable, afforable and intelligent. Our'}</h4>
//                     <h4
//                       className="life_title1_sub_heading"
//                       style={{
//                         margin : 0,
//                         // padding : '0px 14px'
//                         padding : '0px 83px'
//                       }}
//                     >{'platform offers everything from instant quotes to comparing insurance rates & companies.'}</h4>
//                   </div>

//                   <div
//                   className="flex-right-side second-section"
//                   style={{
//                       display: 'flex',
//                       flexDirection: "column",
//                       margin: "20px 5px"
//                     }}
//                   >
//                     <h3
//                       className="life_title1_heading"

//                     >{t("main.onlineLifeInsuranceTitle")}</h3>

//                     <h4
//                       className="life_title1_sub_heading"
//                     >{t("main.onlineLifeInsuranceSubTitle")}</h4>
//                   </div>

//                   <div
//                   className="flex-right-side lets-begin-section"
//                   style={{
//                     display: 'flex',
//                     justifyContent: "space-evenly",
//                     margin: "20px 10px"
//                   }}>
//                     <button onClick={() => history.push("quote/life/IamFamiliar")} className="life_buttons" title="Get Instant Quote">{"Let's Begin"}</button>
//                   </div>
//                 </div>
//               </div>
//              </div>
//           </Desktop>
//           <Tablet>
//           <div
//               className="hero-section"
//               style={{
//                 display: "flex" ,
//                 flexDirection : 'column'
//               }}
//             >
//               <div
//                className={'upper-div'}
//               >
//                 <div
//                   className="life_landing_image"
//                 >
//                   {/**Image */}
//                   <div className="life-img"
//                   style={{
//                     background : `url(${appimages?.life})  no-repeat top center`,
//                     backgroundSize: "contain"

//                   }}
//                   >
//                     {''}

//                   </div>
//                 </div>

//                 <div
//                   className="flex-wrapper"
//                   style={{
//                     display: 'flex',
//                     flex: 1,
//                     flexDirection: 'column'
//                   }}
//                 >
//                   <div
//                   className="flex-right-side first-section"
//                   style={{
//                       display: 'flex',
//                       flexDirection: "column",
//                       margin: "20px 0px",
//                     }}
//                   >
//                     <h3
//                     style={{
//                       margin : 0
//                     }}
//                       className="life_title1_heading"
//                     >{'GetCovered: Your virtual'}</h3>
//                     <h3
//                       className="life_title1_heading"
//                       style={{
//                         margin : 0
//                       }}
//                     >{'life insurance advisor'}</h3>

//                     <h4
//                       style={{
//                       margin : '10px 0 0'
//                     }}
//                       className="life_title1_sub_heading"
//                     >{'Reliable, afforable and intelligent. Our'}</h4>
//                     <h4
//                       className="life_title1_sub_heading first-section-desc-second-line"
//                       style={{
//                         margin : 0,
//                         padding : 0
//                       }}
//                     >{'platform offers everything from instant quotes to comparing insurance rates & companies.'}</h4>
//                   </div>

//                   <div
//                   className="flex-right-side second-section"
//                   style={{
//                       display: 'flex',
//                       flexDirection: "column",
//                       margin: "20px 5px"
//                     }}
//                   >
//                     <h3
//                       className="life_title1_heading"

//                     >{t("main.onlineLifeInsuranceTitle")}</h3>

//                     <h4
//                       className="life_title1_sub_heading"
//                     >{t("main.onlineLifeInsuranceSubTitle")}</h4>
//                   </div>

//                   <div
//                   className="flex-right-side lets-begin-section"
//                   style={{
//                     display: 'flex',
//                     justifyContent: "space-evenly",
//                     margin: "20px 10px"
//                   }}>
//                     <button onClick={() => history.push("quote/life/IamFamiliar")} className="life_buttons" title="Get Instant Quote">{"Let's Begin"}</button>
//                   </div>
//                 </div>
//               </div>
//              </div>
//           </Tablet>
//         </Container>
//         <img
//         src="./assets/img/home/landingpage.svg"
//         className="mountain"
//         width={'100%'} height={'100%'}/>

//         <Container as="main" className="container_main1 life-landing">
//           <OurCustomers />
//           <OurFeatures
//             feature={{
//               g1 : t("our_features_li.grid1"),
//               g2 : t("our_features_li.grid2"),
//               g3 : t("our_features_li.grid3"),
//               g4 : t("our_features_li.grid4")
//             }}
//           />
//           <ApplicationFeatures
//                feature={{
//                 gd2         :t("Home_application_features_li.grid2_desc"),
//                 gd1_desc  :t("Home_application_features_li.grid1_desc")

//               }}
//           />
//           <OurServices
//             feature={{
//               des : t("Life_our_services_li.describe")
//             }}
//           />
//           <WhyUs />
//           <Testimonials />
//         </Container>
//         <Baner />
//         <Container
//           as="main"
//           className=" container_main2"
//           style={{ marginTop: "4.5rem" }}
//         >
//           <FAQ />
//         </Container>
//       </Layout>
//     </>
//   );
// }

import "./dihome.css";
import "./disabilityLanding.css";
import "../Lifelanding/Lifelanding.css";
import "../Home/Home.css";
import "../Home/Landing.css";
import { useHistory } from "react-router-dom";
import appimages from "../../assets/img";
import React from "react";
import { Container, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Layout from "../../layout/Layout";
import FAQ from "./FAQ.js";
import ApplicationFeatures from "./ApplicationFeatures";
import Baner from "./Baner";
import OurCustomers from "./OurCustomers";
import OurServices from "./OurServices";
import Testimonials from "./Testimonials.js";
import OurFeatures from "./OurFeatures";
import WhyUs from "./WhyUs.js";
import { useMediaQuery } from "react-responsive";
import ScrollToTop from "../../components/Tools/ScrollToTop";
import { clearCacheData } from "../../utils/commonFunctions";

export default function Home() {
  const { t } = useTranslation();
  const history = useHistory();
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <>
      <ScrollToTop />
      <Layout>
        <div
          style={{
            position: "relative",
            height: "83vh",
          }}
        >
          <Grid className="mx-0 mt-3 lnding_grid_mobile w-100 justify-content-center flex ">
            <Grid.Row>
              <Grid.Column
                className="w-100 justify-content-center flex "
                computer={16}
                tablet={16}
                mobile={16}
                style={{
                  padding: "0px",

                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="landing_logo "
                  src="./assets/img/logo.png"
                  alt="logo"
                  style={{
                    width: "200px",
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="life-landing py-4 lnding_grid_main landing_main_container lifeInsurance-background-image w-100 justify-content-center flex flex-column mt-5 py-5 landing-page">
            <Desktop>
              <div
                className="hero-section"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={"upper-div"}>
                  <div className="life_landing_image"
                    style={{
                      margin: '0px'
                    }}
                  >
                    {/**Image */}
                    <div
                      className="life-img"
                      style={{
                        background: `url(${appimages?.di})  no-repeat top center`,
                        backgroundSize: "contain",
                      }}
                    >
                      {""}
                    </div>
                  </div> 
                  <div
                    className="flex-wrapper"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      marginTop : '-11px'
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom : "20px",
                      }}
                    >
                      <h3
                        style={{
                          margin: 0,
                        }}
                        className="life_title1_heading"
                      >
                        {"GetCovered: Your virtual"}
                      </h3>
                      <h3
                        className="life_title1_heading"
                        style={{
                          margin: 0,
                        }}
                      >
                        {"disability insurance advisor"}
                      </h3>

                      <h4
                        style={{
                          margin: "10px 0 0",
                          // padding : '0px 35px',
                          width: "inherit",
                        }}
                        className="life_title1_sub_heading"
                      >
                        {"Let's find the disability policy that fits your"}
                      </h4>
                      <h4
                        className="life_title1_sub_heading section-one-desc-line-2"
                        style={{
                          margin: 0,
                          // padding: "0px 14px",
                          // width: "max-content",
                        }}
                      >
                        {"financial objectives and best suits your occupation."}
                      </h4>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // margin: "20px 5px",
                        margin: "10px 20px",
                      }}
                    >
                      <h3 className="life_title1_heading">
                        {"Disability Insurance Quote"}
                      </h3>

                      <h4 className="life_title1_sub_heading">
                        {"In the event of an injury or illness, ensure your"}
                      </h4>
                      <h4
                        className="life_title1_sub_heading"
                        style={{
                          marginTop: "-15px",
                        }}
                      >
                        {"financial security with disability insurance."}
                      </h4>
                    </div>

                    <div
                      className="flex-right-side lets-begin-section"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly", 
                        margin: "45px 0 20px 0px",
                      }}
                    >
                      <button
                        onClick={() => history.push("quote/DI/IamFamiliar")}
                        className="life_buttons"
                        title="Get Instant Quote"
                      >
                        {"Let's Begin"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Desktop>
            <Tablet>
              <div
                className="hero-section"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={"upper-div"}>
                  <div className="life_landing_image">
                    {/**Image */}
                    <div
                      className="life-img"
                      style={{
                        background: `url(${appimages?.life})  no-repeat top center`,
                        backgroundSize: "contain",
                      }}
                    >
                      {""}
                    </div>
                  </div>

                  <div
                    className="flex-wrapper"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className="first-section"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "20px 0px",
                      }}
                    >
                      <h3
                        style={{
                          margin: 0,
                        }}
                        className="life_title1_heading"
                      >
                        {"GetCovered: Your virtual"}
                      </h3>
                      <h3
                        className="life_title1_heading"
                        style={{
                          margin: 0,
                        }}
                      >
                        {"disability insurance advisor"}
                      </h3>

                      <h4
                        style={{
                          margin: "10px 0 0",
                          // padding : '0px 35px',
                          width: "inherit",
                        }}
                        className="life_title1_sub_heading"
                      >
                        {"Let's find the disability policy that fits your"}
                      </h4>
                      <h4
                        className="life_title1_sub_heading section-one-desc-line-2"
                        style={{
                          margin: 0,
                          // padding: "0px 14px",
                          // width : 'max-content'
                        }}
                      >
                        {"financial objectives and best suits your occupation."}
                      </h4>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // margin: "20px 5px",
                        margin: "30px 5px 20px 5px",
                      }}
                    >
                      <h3 className="life_title1_heading">
                        {"Disability Insurance Quote"}
                      </h3>

                      <h4 className="life_title1_sub_heading">
                        {"In the event of an injury or illness, ensure your"}
                      </h4>
                      <h4
                        className="life_title1_sub_heading"
                        style={{
                          marginTop: "-15px",
                        }}
                      >
                        {"financial security with disability insurance."}
                      </h4>
                    </div>

                    <div
                      className="flex-right-side lets-begin-section"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        // margin: "20px 10px",
                        margin: "37px 10px 20px 10px",
                      }}
                    >
                      <button
                        onClick={() => history.push("quote/DI/IamFamiliar")}
                        className="life_buttons"
                        title="Get Instant Quote"
                      >
                        {"Let's Begin"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Tablet>
          </div>
          <div
            className="bottom-svg"
            style={{
              marginTop: "-75px",
            }}
          >
            <img
              alt="landingpage"
              style={{ width: "100%", height: "100%" }}
              src="./assets/img/home/landingpage.svg"
            />
          </div>
        </div>
        <Container as="main" className="pt-4 container_main1">
          <OurCustomers cust_describe={t("our_customers_di.describe")} />
          <OurFeatures
            feature={{
              des: t("our_features_di.describe"),
              g1: t("our_features_di.grid1"),
              g2: t("our_features_di.grid2"),
              g3: t("our_features_di.grid3"),
              g4: t("our_features_di.grid4"),
            }}
          />
          <ApplicationFeatures
            features={{
              sub_title: t("Home_application_features_di.sub_title"),
              gd: t("Home_application_features_di.grid1_desc"),
              gd2: t("Home_application_features_di.grid2_desc"),
              gd3: t("Home_application_features_ci.grid3_desc"),
            }}
          />
          <OurServices />
          <WhyUs
            features={{
              des: t("why_aplus_di.describe"),
            }}
          />
          <Testimonials />
        </Container>
        <Baner />
        <Container
          as="main"
          className=" container_main2"
          style={{ marginTop: "4.5rem" }}
        >
          <FAQ />
        </Container>
      </Layout>
    </>
  );
}
