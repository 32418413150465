import React, { createContext, useEffect, useRef, useState } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useTranslation } from "react-i18next";
import { default as config } from "../constants/config.json";

import "semantic-ui-css/semantic.min.css";
import "../styles/index.scss";
import "../styles/ui.grid.css";
import "./Layout.css";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import { details, login, register, forgot } from "../crud/user";
import { Button, Form, Input, Modal, Tab } from "semantic-ui-react";
import { storeGet, storeSet } from "../crud/storage";
// import PWAPrompt from "../components/PWA-Prompt/PWA-Prompt";
import { useHistory, useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";

export const UserContext = createContext(null);

function Layout({ children }) { 
  let location = useLocation();
    
  const [userData, setUserData] = useState(null);
  const [submittingQoute, setSubmittingQoute] = useState(false);
  
  useEffect(() => {
    window.gtag("config", config.GoogleAnalytics.code, {
      page_path: window.location.hash,
    });
  }, [location]);

  const _userDetails = () => {
     // console.log('calling twice');
    details()
      .then((response) => {
        setUserData(response.data.success);
      })
      .catch((error) => {
        setSubmittingQoute(false);
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          storeSet("user", null); 
        }  
        if(error?.response?.data && error?.response?.data?.message) {
            toast({
              type: "error",
              title: error?.response?.data?.message,
               animation: "bounce",
              // time: 5000
            });
        } 
      });
  };

  useEffect(() => {
    const userStorage = storeGet("user");
    if (userStorage && userStorage.token) {
       // console.log('_userDetails 1');
      _userDetails();
    }
  }, []);
   
  return (
    <>
      <>
        <Header />
        {children}
        {/* <LazyLoad once> */}
        <Footer />
        {/* </LazyLoad> */}

        <SemanticToastContainer position="bottom-right" />
      </>
    </>
  );
}

export default Layout;
