import "./loading.css";

export default function Loading() {
  return (
    <div className="loading">
      <div className="wrapper">
        <div className="img-wrapper">
          <img
            src="./assets/img/logo192.png"
            alt="GetCovered"
            title="GetCovered"
          />
        </div>
        <div className="bullets">
          <i className="bullet bullet--1"></i>
          <i className="bullet bullet--2"></i>
          <i className="bullet bullet--3"></i>
          <i className="bullet bullet--4"></i>
        </div>
      </div>
    </div>
  );
}
