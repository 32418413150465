import React, { Suspense, useEffect, useState } from "react";

import { Grid, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
export default function ApplicationFeatures(props) {
  const {
    features
  } = {...props}
  const { t } = useTranslation();
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <div className="mt-0 w-100 flex flex-column justifiy-content-center ">
          <div
            style={{
              display: " flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3 className="heading-landing-gold ">
              {" "}
              {t("Home_application_features.title")}
            </h3>
            <h3
              className="heading-landing-blue pr-3"
              style={{ marginTop: "-10px" }}
            >
              { features?.sub_title ? features?.sub_title : t("Home_application_features.sub_title")}
            </h3>
            <h3
              className="text-landing pr-3"
              style={{ marginTop: "-10px", maxWidth: " 500px" }}
            >
              {t("Home_application_features.describe")}
            </h3>
          </div>
          <div>
            <div className="mt100">
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    {" "}
                    <img
                      className="instance"
                      alt="instance"
                      //src="./assets/img/landing/instantquote.png"
                      src="./assets/img/landing/mobile.svg" 
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <div className="aplication-features-div">
                      <div className="aplication-features-div-text">
                        <h2 style={{ textTransform: "none" }}>
                          {" "}
                          {t("Home_application_features.grid1_title")}
                        </h2>
                        <h3
                          className="text-landing text-left"
                          style={{ marginTop: "-5px", marginBottom: "30px" }}
                        >
                           {
                          features.gd
                          ?
                          features.gd
                          :
                          t("Home_application_features.grid1_desc")
                          }
                        </h3>
                        <div className="flex d-flex">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                            id="google-play"
                            className="mr-2"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/google-play-sm.png"
                              alt="Get Covered Google Play"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href="https://apps.apple.com/us/app/get-covered/id1567827832"
                            id="app-store"
                            className="mr-2"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/app-store-sm.png"
                              alt="Get Covered App Store"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ marginTop: "10px" }}>
                  <Grid.Column width={8}>
                    {" "}
                    <div className="aplication-features-div">
                      <div className="aplication-features-div-text">
                        <h2 style={{ textTransform: "none" }}>
                          {" "}
                          {t("Home_application_features.grid2_title")}
                        </h2>
                        <h3
                          className="text-landing text-left"
                          style={{ marginTop: "-5px", marginBottom: "30px" }}
                        >
                          {
                            features.gd2
                            ?
                            features.gd2
                            :
                            t("Home_application_features.grid2_desc")
                          }
                        </h3>
                        <div className="flex d-flex">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                            id="google-play"
                            className="mr-2"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/google-play-sm.png"
                              alt="Get Covered Google Play"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href="https://apps.apple.com/us/app/get-covered/id1567827832"
                            id="app-store"
                            className="mr-2"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/app-store-sm.png"
                              alt="Get Covered App Store"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <img
                      className="instance"
                      alt="instance"
                      //src="./assets/img/landing/accuraterecommend.png"
                      src="./assets/img/landing/tablet.svg"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginTop: "80px" }}>
                  <Grid.Column width={8}>
                    {" "}
                    <img
                      className="instance"
                      alt="instance"
                      src="./assets/img/landing/compare.svg"
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <div
                      className="aplication-features-div"
                      style={{ marginTop: "-70px" }}
                    >
                      <div className="aplication-features-div-text">
                        <h2 style={{ textTransform: "none" }}>
                          {" "}
                          {t("Home_application_features.grid3_title")}
                        </h2>
                        <h3
                          className="text-landing text-left"
                          style={{ marginTop: "-5px", marginBottom: "30px" }}
                        >
                          {
                            features?.gd3
                            ?
                            features?.gd3
                            :
                            t("Home_application_features.grid3_desc")
                          }
                        </h3>
                        <div className="flex d-flex">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                            id="google-play"
                            className="mr-2"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/google-play-sm.png"
                              alt="Get Covered Google Play"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href="https://apps.apple.com/us/app/get-covered/id1567827832"
                            id="app-store"
                            className="mr-2"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/app-store-sm.png"
                              alt="Get Covered App Store"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div
          className="mt-0 w-100 flex flex-column justifiy-content-center px-4 "
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: " flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3 className="heading-landing-gold ">
              {" "}
              {t("Home_application_features.title")}
            </h3>
            <h3
              className="heading-landing-blue "
              style={{ marginTop: "-10px" }}
            >
              { features?.sub_title ? features?.sub_title : t("Home_application_features.sub_title")}
            </h3>
            <h3
              className="text-landing text-left"
              style={{ marginTop: "-10px" }}
            >
              {t("Home_application_features.describe")}
            </h3>
          </div>

          <Grid
            padded
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            className="flex py-4 flex-column justify-content-center"
          >
            <Grid.Row
              className="py-4 m-0"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <img
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  alt="instance"
                  //src="./assets/img/landing/instantquote.png"
                      src="./assets/img/landing/mobile.svg" 
                />
              </Grid.Column>
              <Grid.Column
                className="py-4"
                computer={8}
                tablet={16}
                mobile={16}
              >
                <div className="aplication-features-div py-4">
                  <div className="aplication-features-div-text">
                    <h2 style={{ textTransform: "none" }}>
                      {" "}
                      {t("Home_application_features.grid1_title")}
                    </h2>
                    <h3
                      className="text-landing text-left"
                      style={{
                        marginTop: "-5px",
                        marginBottom: "30px",
                        textAlign: "left",
                      }}
                    >
                      {
                      features.gd
                      ?
                      features.gd
                      :
                      t("Home_application_features.grid1_desc")
                      }
                    </h3>
                    <div className="flex d-flex">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                        id="google-play"
                        className="mr-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/img/app-buttons/google-play-sm.png"
                          alt="Get Covered Google Play"
                          height="50px"
                          width="167px"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/us/app/get-covered/id1567827832"
                        id="app-store"
                        className="mr-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/img/app-buttons/app-store-sm.png"
                          alt="Get Covered App Store"
                          height="50px"
                          width="167px"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ marginTop: "-2em" }}>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                <img
                  alt="instance"
                  className="instance"
                  //src="./assets/img/landing/accuraterecommend.png"
                      src="./assets/img/landing/tablet.svg"
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="py-4"
              >
                {" "}
                <div className="aplication-features-div py-4">
                  <div className="aplication-features-div-text">
                    <h2 style={{ textTransform: "none" }}>
                      {" "}
                      {t("Home_application_features.grid2_title")}
                    </h2>
                    <h3
                      className="text-landing text-left"
                      style={{
                        marginTop: "-5px",
                        marginBottom: "30px",
                        textAlign: "left",
                      }}
                    >
                      {
                         features.gd2
                         ?
                         features.gd2
                         :
                        t("Home_application_features.grid2_desc")
                      }
                    </h3>
                    <div className="flex d-flex">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                        id="google-play"
                        className="mr-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/img/app-buttons/google-play-sm.png"
                          alt="Get Covered Google Play"
                          height="50px"
                          width="167px"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/us/app/get-covered/id1567827832"
                        id="app-store"
                        className="mr-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/img/app-buttons/app-store-sm.png"
                          alt="Get Covered App Store"
                          height="50px"
                          width="167px"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "1em" }}>
              <Grid.Column computer={8} tablet={16} mobile={16}>
                {" "}
                <img
                  alt="instance"
                  className="instance"
                  src="./assets/img/landing/compare.svg"
                />
              </Grid.Column>
              <Grid.Column
                computer={8}
                tablet={16}
                mobile={16}
                className="py-4"
              >
                <div className="aplication-features-div py-4">
                  <div className="aplication-features-div-text">
                    <h2 style={{ textTransform: "none" }}>
                      {" "}
                      {t("Home_application_features.grid3_title")}
                    </h2>
                    <h3
                      className="text-landing text-left"
                      style={{
                        marginTop: "-5px",
                        marginBottom: "30px",
                        textAlign: "left",
                      }}
                    >
                      {
                        
                        features?.gd3
                        ?
                        features?.gd3
                        :
                        t("Home_application_features.grid3_desc")
                      }
                    </h3>
                    <div className="flex d-flex">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                        id="google-play"
                        className="mr-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/img/app-buttons/google-play-sm.png"
                          alt="Get Covered Google Play"
                          height="50px"
                          width="167px"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/us/app/get-covered/id1567827832"
                        id="app-store"
                        className="mr-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/img/app-buttons/app-store-sm.png"
                          alt="Get Covered App Store"
                          height="50px"
                          width="167px"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Tablet>
    </>
  );
}
