import React, { useRef } from "react";
import "./index.css";
import Carousel from "nuka-carousel";   

const Slide = (props) => { 
   
  return (
    <React.Fragment>
      <div className="container">
        {/* image and heading container */}
        <div className="Top">
          {/* heading container */}
          <div className="heading-container"
            style={{
               marginBottom : props.image.link.includes('secondSlide.png') ? '10px' : props.image.link.includes('thirdSlide.png') ? '-50px' : '35px'
            }}
          >
            <p className="heading-text no-font-family">{props.image.heading}</p>
          </div>
            {/* image container */}
            <div className="image-slider-main">
              <Carousel 
                className="slider-carousel" 
              >  
                <img
                  src={props?.image?.link}
                  alt={props?.link}
                  style={{
                    padding: 0, 
                    zIndex: 0,
                    width : '100%',
                    // maxWidth : '70%' ,
                    maxWidth : '85%' ,
                    objectFit: "contain" ,
                    userSelect: 'none'
                  }}
                /> 
              </Carousel>
            </div>
          {/* description container */}
          <div className="main-holder">
            {/* arrows and dots container */}
            <div
              className="slide-icon-container"
              style={{
                marginTop: props.image.link.includes("secondSlide.png")
                  ? "5px"
                  : props.image.link.includes("thirdSlide.png")
                  ? "15px"
                  : "5px",
              }}
            >
              {/* prev arrow */}
              <div onClick={props.slidePrev} className="buttons left-arrow"></div>
              {/* slide dots */}
              <div className="dots-container">
                <span
                  className="dot"
                  style={{ background: props.id.id == 1 ? "white" : "" }}
                ></span>
                <span
                  className="dot"
                  style={{ background: props.id.id == 2 ? "white" : "" }}
                ></span>
                <span
                  className="dot"
                  style={{ background: props.id.id == 3 ? "white" : "" }}
                ></span>
              </div>
              {/* next arrow */}
              <div
                onClick={props.slideNext}
                className="buttons right-arrow"
              ></div>
            </div>
            {/* sub header */}
            <div className="highlight-title-holder" id={props?.id?.title.toLowerCase().replace(' ','')} >
              <p className="highlight-title">{props.image.highlightText}</p>
            </div>
            {/* sub points description */}
            <div className="bullet-points-container" id={props?.id?.title.toLowerCase().replace(' ','')}>
              <ul className="list">
                {props.image.points.map((it, ind) => {
                  return <li className="listText">{it}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const App = (props) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  let currentIndex = React.useRef(0);
  const slides = [
    {
      id: 1,
      title: "Life Insurance",
      des: "The steps are simple and only take a few minutes to complete.",
      // link: "./assets/images/logo2.png",
      link: "./assets/images/slide-one.png",
      heading: "Compare the most reliable insurance companies",
      highlightText: "It will only take a minute, but you can save big!",
      points: [
        "Learn more about what the best insurance companies have to offer.",
        "Compare the insurance companies and their products to save money.",
        "Save up to 70% simply by comparing the insurance company.",
      ],
    },
    {
      id: 2,
      title: "Critical Illness Insurance",
      des: "The steps are simple and only take a few minutes to complete.",
      // link: "./assets/images/laptop.png",
      link: "./assets/images/slide-two.png",
      heading: "Compare the rates and policies to find proper coverage.",
      highlightText: "Policy and rate information at your fingertips.",
      points: [
        "Instantly compare rates and policies.",
        "Smart rating system for proper comparison.",
        "Sort your options in the most convenient way.",
      ],
    },
    {
      id: 3,
      title: "Disability Insurance",
      des: "The steps are simple and only take a few minutes to complete.",
      // link: "./assets/images/slide-3.png",
      link: "./assets/images/slide-three.png",
      heading: "Use our intelligent insurance advisor!",
      highlightText: "The insurance solution that works.",
      points: [
        " Let our virtual advisor guide you. ",
        "Get personalized recommendation.",
        "Find the right policy.",
      ],
    },
  ];
  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });

    currentIndex.current =
      currentIndex.current + 1 === slides.length ? 0 : currentIndex.current + 1; 
    
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });

    currentIndex.current =
      currentIndex.current === 0 ? slides.length - 1 : currentIndex.current - 1; 
    
  };

  return (
    <> 
      <Slide
        image={slides[currentIndex.current]}
        id={slides[currentIndex.current]}
        currentIndex = {currentIndex.current}
        slideNext={slideNext}
        slidePrev={slidePrev}
      />
    </>
  );
};

export default App;
 
 