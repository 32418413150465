import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Image,
  Menu,
  Popup,
} from "semantic-ui-react";
import { UserContext } from "../NLayout";
import { default as config } from "../../constants/config.json";
import { useMediaQuery } from "react-responsive";
import "./Header.css";
import { Link, useHistory, useLocation } from "react-router-dom";

import LazyLoad from "react-lazyload";

const style = {
  marginLeft: "-150px",
  marginTop: "20px",
  borderRadius: "10px",
  width: "229px",
  height: "136px",
  padding: "0px",

  /* Blue brand */
  background: "#307FD1",
  boxShadow: "0px 34px 24px -15px rgba(0, 0, 0, 0.14)",
};

const styleUser = {
  marginLeft: "-150px",
  marginTop: "20px",
  borderRadius: "10px",
  width: "200px",
  height: "max-content",
  padding: "5px",

  /* Blue brand */
  background: "#FFF",
  boxShadow: "0px 34px 24px -15px rgba(0, 0, 0, 0.14)",
};
function Header() {
  const { t } = useTranslation();
  let history = useHistory();
  let location = useLocation();
  const { userData, userActions } = useContext(UserContext);

  // useEffect(() => {
  //    console.log(userData);
  // }, [userData]);

  const [visibleSidebar, setVisibleSidebar] = useState(false);
  function showSidebar(e) {
    e.preventDefault();
    setVisibleSidebar(true);
  }
  function hideSidebar(e) {
    setVisibleSidebar(false);
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };
  return (
    <>
      <Desktop>
        <>
          <header
            id="main-header"
            className="px-2 flex justify-content-center d-flex"
          >
            <Grid padded className="w-75 ">
              <Grid.Row className="justify-content-between">
                <Grid.Column
                  computer={6}
                  mobile={16}
                  tablet={16}
                  className="d-flex align-items-center"
                >
                  <Link to="/">
                    <Image
                      src="./assets/img/logo_new.svg"
                      alt={t("main.mainTitle")}
                      title={t("main.mainTitle")}
                      loading="lazy"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    />
                  </Link>
                </Grid.Column>

                <Grid.Column
                  computer={10}
                  mobile={16}
                  tablet={12}
                  className="d-flex align-items-center  justify-content-md-end justify-content-center justify-item-center  mt-md-0 mt-3"
                >
                  <Dropdown
                    className=" mx-4"
                    text={
                      <div>
                        <p className="h8" style={{ color: "#105399" }}>
                          {" "}
                          {t("main.Support")}
                        </p>{" "}
                      </div>
                    }
                  >
                    <Dropdown.Menu style={style}>
                      <div
                        style={{
                          display: "flex",
                          flexDirecion: "row",
                          height: "30px",
                          marginTop: "10px",
                        }}
                      >
                        <p
                          className="say_hello"
                          style={{
                            padding: "20px",
                          }}
                        >
                          {t("main.Hello")}
                        </p>{" "}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirecion: "column",
                          height: "30px",
                        }}
                      >
                        <a
                          href="mailto: Contact@AplusWealth.com"
                          className=" mx-1 mr-md-4 text-white support_text"
                          style={{ padding: "20px" }}
                        >
                          <Icon
                            name="envelope"
                            className="d-md-inline d-none pr-1"
                          />
                          Contact@AplusWealth.com
                        </a>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirecion: "column",
                        }}
                      >
                        <a
                          href="tel: +1-888-461-6120"
                          className="mx-1 mr-2 text-white support_text"
                          style={{ padding: "20px" }}
                        >
                          <Icon
                            name="phone"
                            flipped="horizontally"
                            className="d-md-inline d-none pr-1"
                          />
                          +1-888-461-6120
                        </a>
                      </div>
                      {/* <Dropdown.Item></Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      style={{
                        color: '#fff',
                        padding: '20px',
                        marginTop: '20px',
                      }}
                    >
                      Say hello
                    </p>{' '}
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p style={{ color: '#fff' }}>Support</p>{' '}
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <h8 style={{ color: '#fff' }}>Support</h8>{' '}
                  </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="slicer mx-3"></div>

                  {userData && (
                    <Dropdown
                      className="d-none d-md-block"
                      direction="left"
                      text={
                        <div>
                          {userData.avatar && userData.avatar.length > 0 && (
                            // <Image
                            //   loading="lazy"
                            //   src={`${
                            //     config[process.env.NODE_ENV].backendFileBaseUrl
                            //   }${userData.avatar}`}
                            //   avatar
                            // />
                            <Image
                              src="./assets/img/user.png"
                              alt="user"
                              title="user"
                              className="user  "
                              loading="lazy"
                              style={{ marginTop: "-3px" }}
                            />
                          )}
                          {!userData.avatar && userData.avatar.length === 0 && (
                            <Image
                              src="./assets/img/user.png"
                              alt="user"
                              title="user"
                              className="user mt-1"
                              loading="lazy"
                              style={{ marginTop: "-3px" }}
                            />
                          )}
                          <span className="h8 " style={{ color: "#105399" }}>
                            {userData.name}
                          </span>
                        </div>
                      }
                    >
                      <Dropdown.Menu style={styleUser}>
                        <Dropdown.Item>
                          <Link to="/panel/qoutes">
                            <div className="d-flex w-100 justify-content-start justify-items-center">
                              <Icon
                                name="unordered list  "
                                color={"black"}
                                className="mr-2 mt-1"
                              />{" "}
                              <p className="text-black support_text">
                                {t("panel.qoutes")}
                              </p>
                            </div>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/panel/profile">
                            <div className="d-flex w-100 justify-content-start justify-items-center">
                              <Icon
                                name="user  "
                                color={"black"}
                                className="mr-2 mt-1"
                              />{" "}
                              <p className="text-black support_text">
                                {t("panel.profile")}
                              </p>
                            </div>
                            {/* <Icon name="user circle" /> */}
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/panel/news">
                            <div className="d-flex w-100 justify-content-start justify-items-center">
                              <Icon
                                name="newspaper  "
                                color={"black"}
                                className="mr-2 mt-1"
                              />{" "}
                              <p className="text-black support_text">
                                {t("panel.news")}
                              </p>
                            </div>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/panel/calculators">
                            <div className="d-flex w-100 justify-content-start justify-items-center">
                              <Icon
                                name="calculator  "
                                color={"black"}
                                className="mr-2 mt-1"
                              />{" "}
                              <p className="text-black support_text">
                                {t("panel.calculators")}
                              </p>
                            </div>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={userActions.logout}>
                          <div className="d-flex w-100 justify-content-start justify-items-center">
                            <Icon
                              name="sign-out  "
                              color={"black"}
                              className="mr-2 mt-1"
                            />{" "}
                            <p className="text-black support_text">
                              {t("main.logout")}
                            </p>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {!userData && (
                    <div className="div-button">
                      {location.pathname === "/life-insurance" ? (
                        <button
                          onClick={() =>
                            history.push("/quote/life/IamFamiliar")
                          }
                          className="register-button"
                        >
                          {t("main.getQoute")}
                        </button>
                      ) : location.pathname === "/critical-insurance" ? (
                        <button
                          onClick={() => history.push("/quote/CI/IamFamiliar")}
                          className="register-button"
                        >
                          {t("main.getQoute")}
                        </button>
                      ) : (
                        <button
                          onClick={() => history.push("/quote/DI/IamFamiliar")}
                          className="register-button"
                        >
                          {t("main.getQoute")}
                        </button>
                      )}

                      <button
                        className="login-button"
                        onClick={() => history.push("/register")}
                      >
                        {t("main.register")}
                      </button>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </header>
        </>
      </Desktop>

      {/********************************************** tablet mode *********************************************/}

      <Tablet>
        <>
          {" "}
          <header
            id="main-header"
            className="px-2 flex justify-content-center d-flex"
          >
            <div className="w-100 d-flex d-flex align-items-center justify-content-between">
              <a
                href="/#"
                onClick={showSidebar}
                className=" toggle-sidebar d-block "
              >
                <Icon name="bars" size="big" />
              </a>
              {userData && (
                <div className=" d-flex">
                  <Image
                    src="./assets/img/user.png"
                    alt="user"
                    title="user"
                    className="user  "
                    loading="lazy"
                    style={{ marginTop: "-3px" }}
                  />
                  <span
                    className="h8 mt-1  mr-4 ml-3"
                    style={{ color: "#105399" }}
                  >
                    {userData.name}
                  </span>
                </div>
              )}
            </div>
            {/* <Grid className="w-100 ">
              <Grid.Row className="justify-content-center d-flex">
                <Grid.Column
                  computer={8}
                  mobile={8}
                  tablet={8}
                  className="d-flex align-items-center justify-content-between"
                >
                  ffff
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  mobile={8}
                  tablet={8}
                  className="d-flex align-items-center justify-content-between"
                >
                  <a
                    href="/#"
                    onClick={showSidebar}
                    className="mr-1 toggle-sidebar d-block "
                  >
                    <Icon name="bars" size="big" />
                  </a>
                </Grid.Column>
              </Grid.Row>
            </Grid> */}
          </header>
          <LazyLoad once>
            <div id="main-sidebar" className={visibleSidebar ? "show" : ""}>
              <Menu vertical fluid onClick={hideSidebar}>
                <Menu.Item as={Link} to="/">
                  <div className="w-100 mt-4 d-flex d-flex align-items-center justify-content-between">
                    <Link to="/">
                      <Image
                        src="./assets/img/logo.png"
                        alt={t("main.mainTitle")}
                        title={t("main.mainTitle")}
                        className="logo"
                        loading="lazy"
                        style={{ width: "170px" }}
                      />
                    </Link>
                    <Image
                      src="./assets/img/cancel.png"
                      alt={t("main.mainTitle")}
                      title={t("main.mainTitle")}
                      loading="lazy"
                      onClick={() => {
                        // setFormValues(defaultFormValues);
                        hideSidebar();
                      }}
                    />
                  </div>
                </Menu.Item>

                {!userData && (
                  <>
                    <Menu.Item>
                      <div className="container flex-column p-3 w-100">
                        <Link to={"/login"} className="d-flex w-100">
                          <Icon
                            name="sign-in"
                            size={"large"}
                            className="mr-4"
                          />
                          <p className="header-mobile-text">
                            {" "}
                            {t("main.login")}
                          </p>
                        </Link>
                        <Link to={"/register"} className="d-flex py-4 w-100">
                          <Icon
                            name="user plus"
                            size={"large"}
                            className="mr-4"
                          />
                          <p className="header-mobile-text">
                            {" "}
                            {t("main.register")}
                          </p>
                        </Link>
                      </div>
                    </Menu.Item>
                  </>
                )}

                {userData && (
                  <>
                    <Menu.Item>
                      <div className="container flex-column p-3 w-100">
                        <Link
                          to={"/panel/qoutes"}
                          className="d-flex py-3 w-100"
                        >
                          <Icon
                            name="copy outline"
                            size={"large"}
                            className="mr-4"
                          />
                          <p className="header-mobile-text">
                            {" "}
                            {t("panel.qoutes")}
                          </p>
                        </Link>
                        <Link
                          to={"/panel/profile"}
                          className="d-flex py-3 w-100"
                        >
                          <Icon
                            name="user circle"
                            size={"large"}
                            className="mr-4"
                          />
                          <p className="header-mobile-text">
                            {t("panel.profile")}
                          </p>
                        </Link>
                        <Link to={"/panel/news"} className="d-flex py-3  w-100">
                          <Icon
                            name="newspaper outline"
                            size={"large"}
                            className="mr-4"
                          />
                          <p className="header-mobile-text">
                            {t("panel.news")}
                          </p>
                        </Link>
                        <Link
                          to={"/panel/calculators"}
                          className="d-flex py-3 w-100"
                        >
                          <Icon
                            name="calculator"
                            size={"large"}
                            className="mr-4"
                          />
                          <p className="header-mobile-text ">
                            {t("panel.calculators")}
                          </p>
                        </Link>
                      </div>
                    </Menu.Item>
                    <Menu.Item as={Link} onClick={userActions.logout}>
                      <span>
                        <Icon
                          name="sign-out"
                          size={"large"}
                          className="mr-4 ml-3"
                        />
                        {t("main.logout")}
                      </span>
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </div>
            <div
              id="sidebar-backdrop"
              className={visibleSidebar ? "show" : ""}
              onClick={hideSidebar}
            ></div>
          </LazyLoad>
        </>
      </Tablet>
    </>
  );
}

export default Header;
