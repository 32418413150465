import "./loading.css";

export default function Loading({title}) {
  
  return (
    <div className="splash-loading">
      <img
        src="./assets/img/logo200.png"
        alt="GetCovered"
        title="GetCovered"
      />

      <h3
        className="heading-landing-blue w-100"
        style={{
          fontSize: "18px",
          lineHeight: "24px",
          textAlign: "center",
          Width: "550px",
          marginTop: "-0.5rem",
        }}
      >
        Hard at work…
      </h3>
      <h3
        className="heading-landing-blue w-100"
        style={{
          fontSize: "16px",
          lineHeight: "22px",
          textAlign: "center",
          Width: "550px",
          marginTop: "-1rem",
        }}
      >
        {
          localStorage.getItem('cardType') == 'di' ?
          'Finding the best disability insurance rates for you!' :
          localStorage.getItem('cardType') == 'ci' ?
          'Finding the best critical insurance rates for you!' :
          'Finding the best life insurance rates for you!'
        }
      </h3>

      <div className="bullets">
        <i className="bullet bullet--1"></i>
        <i className="bullet bullet--2"></i>
        <i className="bullet bullet--3"></i>
        <i className="bullet bullet--4"></i>
      </div>
    </div>
  );
}
