import React, { Suspense, useEffect, useState } from "react";

import { Grid, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

export default function OurFeatures(props) {
  const {feature}={...props} ;
  const { t } = useTranslation();

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1199 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <div className="mt100 w-100 flex d-flex justifiy-content-end pb-2  mb-5">
          <div
            style={{
              maxWidth: " 568px",
            }}
          >
            <h3 className="heading-landing-gold"> {t("our_features_li.title")}</h3>
            <h3
              className="heading-landing-blue pr-3 "
              style={{ marginTop: "-10px", maxWidth: " 95%" }}
            >
              {t("our_features_li.sub_title")}
            </h3>
            <h3
              className="text-landing pr-3"
              style={{ marginTop: "-10px", maxWidth: " 500px" }}
            >
              {t("our_features_li.describe")}
            </h3>

            <img
              className="ourfeaturessvg"
              src="./assets/img/landing/ourfeatures.svg"
              alt="ourfeaturessvg"
            />
          </div>
          <div
            style={{
              width: "60%",
            }}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  <div className="umbrella-box">
                    <div className="flex d-flex">
                      <img
                        // className="umbrella-image"
                        src="./assets/img/landing/time.png"
                        width={"80px"}
                        height={"65px"}
                        alt="umbrellaImage"
                      />
                      <p
                        className="landingcardtitletext new-title"
                        style={{
                          marginTop: "1.8rem",
                          textAlign: "left",
                          marginLeft: "1rem",
                        }}
                      >
                        {t("our_features_li.grid1_title")}
                      </p>
                    </div>
                    <h3
                      className="text-landing"
                      style={{
                        marginTop: "1rem",
                        marginBottom: "30px",
                        textAlign: "left",
                      }}
                    >
                      { feature.g1 ? feature.g1  : t("our_features_li.grid1")}
                    </h3>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  {" "}
                  <div className="umbrella-box">
                    <div className="flex d-flex">
                      <img
                        // className="umbrella-image"
                        src="./assets/img/landing/user.png"
                        width={"80px"}
                        height={"65px"}
                        alt="umbrellaImage"
                      />
                      <p
                        className="landingcardtitletext new-title"
                        style={{
                          marginTop: "1.8rem",
                          textAlign: "left",
                          marginLeft: "1rem",
                        }}
                      >
                        {t("our_features_li.grid2_title")}
                      </p>
                    </div>
                    <h3
                      className="text-landing"
                      style={{
                        marginTop: "1rem",
                        marginBottom: "30px",
                        textAlign: "left",
                      }}
                    >
                      { feature.g2 ? feature.g2  : t("our_features_li.grid2")}
                    </h3>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  {" "}
                  <div className="umbrella-box">
                    <div className="flex d-flex">
                      <img
                        // className="umbrella-image"
                        src="./assets/img/landing/smart.png"
                        width={"80px"}
                        height={"65px"}
                        alt="landing"
                      />
                      <p
                        className="landingcardtitletext new-title"
                        style={{
                          marginTop: "1.8rem",
                          textAlign: "left",
                          marginLeft: "1rem",
                        }}
                      >
                        {t("our_features_li.grid3_title")}
                      </p>
                    </div>
                    <h3
                      className="text-landing"
                      style={{
                        marginTop: "1rem",
                        marginBottom: "30px",
                        textAlign: "left",
                      }}
                    >
                      { feature.g3 ? feature.g3  : t("our_features_li.grid3")}
                    </h3>
                  </div>
                </Grid.Column>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  {" "}
                  <div className="umbrella-box">
                    <div className="flex d-flex">
                      <img 
                        src="./assets/img/landing/customer.png"
                        width={"80px"}
                        height={"65px"}
                        alt="landing"
                      />
                      <p
                        className="landingcardtitletext new-title"
                        style={{
                          marginTop: "1.8rem",
                          textAlign: "left",
                          marginLeft: "1rem",
                        }}
                      >
                        {t("our_features_li.grid4_title")}
                      </p>
                    </div>{" "}
                    <h3
                      className="text-landing"
                      style={{
                        marginTop: "1rem",
                        marginBottom: "-5px",
                        textAlign: "left",
                      }}
                    >
                      { feature.g4 ? feature.g4  : t("our_features_li.grid4")}
                      <p style={{ visibility: "hidden" }}>
                        right product that suits your unique needs.
                      </p>
                    </h3>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Desktop>
      {/* /*******************************  resposive mobile ********************** */}
      <Tablet>
        <div className="mt-5 pt-4 w-100 flex flex-column justify-content-center ">
          <div className="w-100  flex flex-column justify-content-center px-4">
            <h3 className="heading-landing-gold"> {t("our_features_li.title")}</h3>
            <h3 className="heading-landing-blue" style={{ marginTop: "-10px" }}>
              {t("our_features_li.sub_title")}
            </h3>
            <h3 className="text-landing" style={{ marginTop: "-10px" }}>
              {t("our_features_li.describe")}
            </h3>
          </div>

          <Grid
            padded
            className="px-4 py-4 flex flex-column justify-content-center"
          >
            <Grid.Row>
              <Grid.Column
                className="py-4 justify-content-center"
                computer={8}
                tablet={16}
                mobile={16}
              >
                <div className="umbrella-box flex justify-content-center w-100">
                  <img
                    // className="umbrella-image"
                    src="./assets/img/landing/time.png"
                    width={"80px"}
                    height={"65px"}
                    alt="umbrellaImage"
                    style={{
                      alignSelf: "center",
                    }}
                  />
                  <p className="landingcardtitletext mt-3 mb-4 new-title text-center">
                    {t("our_features_li.grid1_title")}
                  </p>
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "1px",
                      marginBottom: "30px",
                      textAlign: "left",
                    }}
                  >
                      { feature.g1 ? feature.g1  : t("our_features_li.grid1")}
                  </h3>
                </div>
              </Grid.Column>
              <Grid.Column
                className="py-4"
                computer={8}
                tablet={16}
                mobile={16}
              >
                {" "}
                <div className="umbrella-box">
                  <img
                    // className="umbrella-image"
                    alt="umbrellaImage"
                    src="./assets/img/landing/user.png"
                    width={"80px"}
                    height={"65px"}
                    style={{
                      alignSelf: "center",
                    }}
                  />
                  <p className="landingcardtitletext mt-3 mb-4 new-title text-center">
                    {t("our_features_li.grid2_title")}
                  </p>
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "1px",
                      marginBottom: "30px",
                      textAlign: "left",
                    }}
                  >
                      { feature.g2 ? feature.g2  : t("our_features_li.grid2")}
                  </h3>
                </div>
              </Grid.Column>

              <Grid.Column
                className="py-4"
                computer={8}
                tablet={16}
                mobile={16}
              >
                <div className="umbrella-box">
                  <img
                    // className="umbrella-image"
                    // src="./assets/img/landing/ourfeaturedeactive.svg"
                    src="./assets/img/landing/smart.png"
                    width={"80px"}
                    height={"65px"}
                    alt="landing"
                    style={{
                      alignSelf: "center",
                    }}
                  />
                  <p className="landingcardtitletext mt-3 mb-4 new-title text-center">
                    {t("our_features_li.grid3_title")}
                  </p>
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "1px",
                      marginBottom: "30px",
                      textAlign: "left",
                    }}
                  >
                      { feature.g3 ? feature.g3  : t("our_features_li.grid3")}
                  </h3>
                </div>
              </Grid.Column>
              <Grid.Column
                className="py-4"
                computer={8}
                tablet={16}
                mobile={16}
              >
                {" "}
                <div className="umbrella-box">
                  <img 
                    src="./assets/img/landing/customer.png"
                    width={"80px"}
                    height={"65px"}
                    alt="landing"
                    style={{
                      alignSelf: "center",
                    }}
                  />
                  <p className="landingcardtitletext new-title mt-3 mb-4 text-center">
                    {t("our_features_li.grid4_title")}
                  </p>
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "1px",
                        // marginBottom: "-5px", 
                      textAlign: "left",
                    }}
                  >
                      { feature.g4 ? feature.g4  : t("our_features_li.grid4")}
                    <p style={{ visibility: "hidden" }}>
                      to recommend the right product that suits your unique
                    </p>
                  </h3>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Tablet>
    </>
  );
}
