import { Container, Grid, Icon, Image } from "semantic-ui-react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
let deferredPrompt;

// window.addEventListener("beforeinstallprompt", (e) => {
//   document.querySelector("#pwa").hidden = false;
//   // Prevent the mini-infobar from appearing on mobile
//   e.preventDefault();

//   if (!window.matchMedia("(display-mode: standalone)").matches) {
//     // Stash the event so it can be triggered later.
//     deferredPrompt = e;
//     // Optionally, send analytics event that PWA install promo was shown.
//      // console.log(`'beforeinstallprompt' event was fired.`);
//   }
// });

function Footer() {
  const { t } = useTranslation();
  const location = window.location;
   const downloadPWA = (e) => {
    e.preventDefault();

    if (deferredPrompt) {
      deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          localStorage.setItem("pwaStatus", "accepted");
           // console.log("User accepted the A2HS prompt");
        } else {
          localStorage.setItem("pwaStatus", "denied");
           // console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt = null;
      });

      localStorage.setItem("pwaPrompt", true);
    }
  };

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <footer id="main-footer">
          <div id="top-footer" className="py-3">
            <Container>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div className="flex-column w-100">
                      <Image
                        src="./assets/img/logo_new.svg"
                        alt={t("main.mainTitle")}
                        title={t("main.mainTitle")}
                        loading="lazy"
                        style={{
                          width: "260px",
                          margin: "2rem 0rem",
                        }}
                      />
                      <h3
                        className="text-landing "
                        style={{
                          marginTop: "15px",
                          maxWidth: " 100%",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {
                          location.hash.includes("#/life-insurance") ||
                          location.hash.includes("#/critical-insurance") ||
                          location.hash.includes("#/disability-insurance")
                          ?
                          t("aboutUS_li_ci_di.title")  :
                          t("aboutUS.title")
                        }
                      </h3>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginTop: "-10px" }}>
                  <Grid.Column width={5}>
                    <h3 style={{ margin: "0px" }}>Contact</h3>
                    <p>
                      <a href="tel:+1-888-461-6120" style={{ padding: "0px" }}>
                        <p
                          className="text-landing "
                          style={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          {" "}
                          +1-888-461-6120
                        </p>
                      </a>
                    </p>
                    <p style={{ marginTop: "-5px" }}>
                      <a
                        href="mailto:contact@apluswealth.com"
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        <p
                          className="text-landing "
                          style={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          contact@apluswealth.com
                        </p>
                      </a>
                    </p>
                    <p
                      className="text-landing text-left"
                      style={{ marginTop: "-2px" }}
                    >
                      18 King St E #1400, Toronto, ON M5C 1C4
                    </p>

                    <div className="py-3 d-flex align-items-center justify-content-start">
                      <a
                        href="https://www.linkedin.com/company/apluswealth/"
                        target="_blank"
                        className="mr-2"
                        rel="noreferrer"
                      >
                        <Icon
                          name="linkedin"
                          size="large"
                          color="grey"
                          inverted
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/apluswealth/"
                        target="_blank"
                        className="mr-2"
                        rel="noreferrer"
                      >
                        <Icon
                          name="instagram"
                          size="large"
                          color="grey"
                          inverted
                        />
                      </a>
                      <a
                        href="https://twitter.com/Apluswealth_Inc"
                        target="_blank"
                        className="mr-2"
                        rel="noreferrer"
                      >
                        <Icon
                          name="twitter"
                          size="large"
                          color="grey"
                          inverted
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/apluswealthcanada/"
                        target="_blank"
                        className="mr-2"
                        rel="noreferrer"
                      >
                        <Icon
                          name="facebook"
                          size="large"
                          color="grey"
                          inverted
                        />
                      </a>
                    </div>
                  </Grid.Column>

                  <Grid.Column width={3}>
                    <h3 style={{ margin: "0px" }}>Get insurance</h3>

                    <p>
                      <Link style={{ color: "#4F4F4F" }} to="/quote/life">
                        Life insurance{" "}
                      </Link>
                    </p>

                    <p className="text-left">
                      <Link style={{ color: "#4F4F4F" }} to="/quote/CI">
                        Critical illness insurance
                      </Link>
                    </p>

                    <p>
                      <Link style={{ color: "#4F4F4F" }} to="/quote/DI">
                        Disability insurance
                      </Link>
                    </p>
                  </Grid.Column>

                  <Grid.Column width={3}>
                    <h3 style={{ margin: "0px" }}>About our company</h3>

                    <p>
                      <a
                        href="https://www.apluswealth.com/about-apluswealth/"
                        target="_blank"
                        style={{ color: "#4F4F4F" }}
                        rel="noreferrer"
                      >
                        About us
                      </a>
                    </p>

                    <p>
                      <a
                        href="https://www.apluswealth.com/contact-us/"
                        target="_blank"
                        style={{ color: "#4F4F4F" }}
                        rel="noreferrer"
                      >
                        Contact us
                      </a>
                    </p>
                  </Grid.Column>

                  {location.search.indexOf("ios") === -1 &&
                    location.search.indexOf("android") === -1 && (
                      <Grid.Column computer={4} className="download ">
                        <div className="footer-desktop-download ">
                          <a
                            href="_blank"
                            id="pwa"
                            onClick={downloadPWA}
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/pwa-lg.png"
                              alt="Get Covered PWA"
                              height="50px"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                            id="google-play"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/google-play-lg.png"
                              alt="Get Covered Google Play"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href="https://apps.apple.com/us/app/get-covered/id1567827832"
                            id="app-store"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/app-store-lg.png"
                              alt="Get Covered App Store"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </Grid.Column>
                    )}
                </Grid.Row>
              </Grid>
            </Container>
          </div>
          <Container>
            <div
              id="bottom-footer"
              className="py-3"
              style={{ borderTop: "1px solid #E7E7E7" }}
            >
              <div className="flex d-flex justify-content-between py-1">
                <div className="text-left">
                  2018-2023 © Copyright own AplusWealth Inc.
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    textTransform: "uppercase",
                  }}
                >
                  <Link
                    className="text-center px-5"
                    style={{ color: "#4F4F4F" }}
                    to="/Term-of-use"
                  >
                    Terms of use
                  </Link>
                  <Link
                    className="text-center px-5"
                    style={{ color: "#4F4F4F" }}
                    to="/Privacy-policy"
                  >
                    Privacy policies
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </Desktop>
      <Tablet>
        <footer id="main-footer" className="px-4">
          <div id="top-footer" className="pb-3">
            <Container style={{ marginTop: "-5rem" }}>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column
                    className="w-100 jusfity-content-center flex "
                    computer={4}
                    tablet={16}
                    mobile={16}
                    style={{
                      padding: "0px",

                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      className="landing_logo "
                      src="./assets/img/logo.png"
                      alt="Landing"
                      style={{
                        width: "260px",
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  style={{ marginTop: "-2.3em" }}
                  className="justify-content-between"
                >
                  <Grid.Column width={16}>
                    <div className="flex-column w-100 justify-content-between">
                      {/* <Image
                        src='./assets/img/logo.png'
                        alt={t('main.mainTitle')}
                        title={t('main.mainTitle')}
                        height='40px'
                        width='174px'
                        className='logo'
                        loading='lazy'
                      /> */}
                      <h3
                        className="text-landing "
                        style={{
                          marginTop: "15px",
                          textAlign: "left",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {t("aboutUS.title")}
                      </h3>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginTop: "-10px" }}>
                  <Grid.Column
                    width={16}
                    style={{
                      padding: "0px",
                      flexDirection: "column",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h3 style={{ margin: "0px", textAlign: "center" }}>
                      Contact
                    </h3>
                    <p>
                      <a href="tel:+1-888-461-6120" style={{ padding: "0px" }}>
                        <p
                          className="text-landing "
                          style={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          {" "}
                          +1-888-461-6120
                        </p>
                      </a>
                    </p>
                    <p style={{ marginTop: "-5px" }}>
                      <a
                        href="mailto:contact@apluswealth.com"
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        <p
                          className="text-landing "
                          style={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          contact@apluswealth.com
                        </p>
                      </a>
                    </p>
                    <p className="text-landing " style={{ marginTop: "-2px" }}>
                      18 King St E #1400, Toronto, ON M5C 1C4
                    </p>
                  </Grid.Column>

                  <Grid.Column width={16}>
                    <h3
                      style={{
                        margin: "20px 0px 0px 0px",
                        textAlign: "center",
                      }}
                    >
                      Get insurance
                    </h3>

                    <p className="text-center">
                      <Link
                        style={{ color: "#4F4F4F", textAlign: "center" }}
                        to="/quote/life"
                      >
                        Life insurance{" "}
                      </Link>
                    </p>

                    <p className="text-center">
                      <Link style={{ color: "#4F4F4F" }} to="/quote/CI">
                        Critical illness insurance
                      </Link>
                    </p>

                    <p className="text-center">
                      <Link style={{ color: "#4F4F4F" }} to="/quote/DI">
                        Disability insurance
                      </Link>
                    </p>
                  </Grid.Column>

                  <Grid.Column width={16}>
                    <h3
                      style={{
                        margin: "20px 0px 0px 0px",
                        textAlign: "center",
                      }}
                    >
                      About our company
                    </h3>

                    <p className="text-center">
                      <a
                        href="https://www.apluswealth.com/about-apluswealth/"
                        target="_blank"
                        style={{ color: "#4F4F4F" }}
                        rel="noreferrer"
                      >
                        About us
                      </a>
                    </p>

                    <p className="text-center">
                      <a
                        href="https://www.apluswealth.com/contact-us/"
                        target="_blank"
                        style={{ color: "#4F4F4F" }}
                        rel="noreferrer"
                      >
                        Contact us
                      </a>
                    </p>
                    <div className="py-3 d-flex align-items-center justify-content-center">
                      <a
                        href="https://www.linkedin.com/company/apluswealth/"
                        target="_blank"
                        className="mr-2"
                        rel="noreferrer"
                      >
                        <Icon
                          name="linkedin"
                          size="large"
                          color="grey"
                          inverted
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/apluswealth/"
                        target="_blank"
                        className="mr-2"
                        rel="noreferrer"
                      >
                        <Icon
                          name="instagram"
                          size="large"
                          color="grey"
                          inverted
                        />
                      </a>
                      <a
                        href="https://twitter.com/Apluswealth_Inc"
                        target="_blank"
                        className="mr-2"
                        rel="noreferrer"
                      >
                        <Icon
                          name="twitter"
                          size="large"
                          color="grey"
                          inverted
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/apluswealthcanada/"
                        target="_blank"
                        className="mr-2"
                        rel="noreferrer"
                      >
                        <Icon
                          name="facebook"
                          size="large"
                          color="grey"
                          inverted
                        />
                      </a>
                    </div>
                  </Grid.Column>

                  {location.search.indexOf("ios") === -1 &&
                    location.search.indexOf("android") === -1 && (
                      <Grid.Column width={16} className="download mt-1 mb-4">
                        <div className=" footer-mobile-download">
                          <a
                            href="_blank"
                            id="pwa"
                            onClick={downloadPWA}
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/pwa-lg.png"
                              alt="Get Covered PWA"
                              height="50px"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.apluswealth.getcovered"
                            id="google-play"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/google-play-lg.png"
                              alt="Get Covered Google Play"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href="https://apps.apple.com/us/app/get-covered/id1567827832"
                            id="app-store"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/app-buttons/app-store-lg.png"
                              alt="Get Covered App Store"
                              height="50px"
                              width="167px"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </Grid.Column>
                    )}
                </Grid.Row>
              </Grid>
            </Container>
          </div>
          <Container>
            <div
              id="bottom-footer"
              className="py-3"
              style={{ borderTop: "1px solid #E7E7E7" }}
            >
              <div className="flex d-flex justify-content-center py-1">
                <div className="text-left">
                  2018-2023 © Copyright own AplusWealth Inc.
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </Tablet>
    </>
  );
}

export default Footer;
