import React from "react";
import { Grid, Image, Table, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
export default function WhyUs(props) {
  const {features} = {...props};
  const { t } = useTranslation();
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };
  return (
    <>
      <Desktop>
        <div
          className="flex w-100 flex-column"
          style={{
            position: "relative",
          }}
        >
          <div className="mt100 w-100 flex d-flex justifiy-content-start ">
            <div className="mt-5 w-50">
              <h3 className="heading-landing-gold"> {t("why_aplus.title")}</h3>
              <div className="flex d-flex">
                <h3
                  className="heading-landing-blue"
                  style={{ marginTop: "-5px" }}
                >
                  {t("why_aplus.sub_title")}
                </h3>
              </div>

              <h3 className="text-landing pr-3" style={{ marginTop: "0px" }}>
                {
                  features?.des
                  ?
                  features?.des
                  :
                  t("why_aplus.describe")
                }
              </h3>
            </div>
          </div>
          <div className="eclipse_whyUs"></div>
          <div className="mt-5 w-100">
            <Grid className="mt-5 w-100 pl-5">
              <Grid.Row>
                <Grid.Column width={9}></Grid.Column>
                <Grid.Column width={3}>
                  <p
                    className="whyus_column1"
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {t("why_aplus.column1")}
                  </p>
                </Grid.Column>
                <Grid.Column width={4}>
                  {" "}
                  <p
                    className="whyus_column2"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {t("why_aplus.column2")}
                  </p>
                </Grid.Column>
              </Grid.Row>
              {/* Family-First Features 3 row  */}

              <Grid.Row>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3
                    className="whyus_column1"
                    style={{ paddingTop: "0.8rem", fontSize: "18px" }}
                  >
                    {t("why_aplus.row1_title")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                ></Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                ></Grid.Column>
              </Grid.Row>
              {/* Family-First Features 3 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ paddingTop: "1.2rem" }}>
                    {t("why_aplus.row1_subtitle1")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Family-First Features 3 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ paddingTop: "1.2rem" }}>
                    {t("why_aplus.row1_subtitle2")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  times circle  grey"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Family-First Features 3 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ paddingTop: "1.2rem" }}>
                    {t("why_aplus.row1_subtitle3")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  times circle  grey"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Family-First Features 3 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3
                    className="text-landing"
                    style={{ padding: "1.2rem 0rem" }}
                  >
                    {t("why_aplus.row1_subtitle4")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  times circle  grey"}
                  />
                </Grid.Column>
              </Grid.Row>

              {/* Fast & Easy Application Process
4 row  */}

              <Grid.Row className="mt-1">
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3
                    className="whyus_column1"
                    style={{ paddingTop: "0.8rem", fontSize: "18px" }}
                  >
                    {t("why_aplus.row2_title")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                ></Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                ></Grid.Column>
              </Grid.Row>
              {/* Fast & Easy Application Process
4 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ paddingTop: "1.2rem" }}>
                    {t("why_aplus.row2_subtitle1")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Fast & Easy Application Process
4 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ paddingTop: "1.2rem" }}>
                    {t("why_aplus.row2_subtitle2")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Fast & Easy Application Process
4 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ paddingTop: "1.2rem" }}>
                    {t("why_aplus.row2_subtitle3")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Fast & Easy Application Process
4 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ padding: "1rem 0rem" }}>
                    {t("why_aplus.row2_subtitle4")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  times circle  grey"}
                  />
                </Grid.Column>
              </Grid.Row>

              {/* Fast & Easy Application Process
4 row  */}

              <Grid.Row className="mt-1">
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3
                    className="whyus_column1"
                    style={{ paddingTop: "0.8rem", fontSize: "18px" }}
                  >
                    {t("why_aplus.row3_title")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                ></Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                ></Grid.Column>
              </Grid.Row>
              {/*Honest & Uncomplicated Coverage
4 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ paddingTop: "1.2rem" }}>
                    {t("why_aplus.row3_subtitle1")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Honest & Uncomplicated Coverage
4 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ paddingTop: "1.2rem" }}>
                    {t("why_aplus.row3_subtitle2")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      paddingTop: "1.2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  times circle  grey"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Honest & Uncomplicated Coverage
4 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3 className="text-landing" style={{ padding: "1rem 0rem" }}>
                    {t("why_aplus.row3_subtitle3")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",
                      padding: "1.2rem 0rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  times circle  grey"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Honest & Uncomplicated Coverage
4 row  */}
              <Grid.Row style={{ marginTop: "-2rem" }}>
                <Grid.Column
                  width={9}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  {" "}
                  <h3
                    className="text-landing"
                    style={{ paddingBottom: "1rem" }}
                  >
                    {t("why_aplus.row3_subtitle4")}
                  </h3>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>

                <Grid.Column
                  width={4}
                  style={{ background: "#fff", zIndex: 10 }}
                >
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large  times circle  grey"}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div
          className="flex w-100 flex-column justifiy-content-center px-4"
          style={{
            background:
              "linear-gradient(to bottom, #f3f4f7, #f6f7f9, #f9f9fb, #f9f9fb, #f9f9fb)",
          }}
        >
          <div className=" w-100 flex d-flex justifiy-content-center ">
            <div className="mt-3 w-100 ">
              <h3 className=" heading-landing-gold"> {t("why_aplus.title")}</h3>
              <div className="flex d-flex">
                <h3
                  className="heading-landing-blue"
                  style={{ marginTop: "-5px", width: "100%" }}
                >
                  {t("why_aplus.sub_title")}
                </h3>
              </div>

              <h3
                className="text-landing"
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                {
                  features?.des
                  ?
                  features?.des
                  :
                  t("why_aplus.describe")
                }
              </h3>
            </div>
          </div>

          <div className="mt-5 w-100">
            <Grid padded className="w-100 py-4 ustifiy-content-center row-whyus">
              {/* Family-First Features 3 row  */}

              <Grid.Row style={{ background: "#fff" }}>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  {" "}
                  <h3
                    className="whyus_column1"
                    style={{
                      marginTop: "0px",
                      fontSize: "18px",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row1_title")}
                  </h3>
                </Grid.Column>
              </Grid.Row>
              {/* Family-First Features 3 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row1_subtitle1")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Family-First Features 3 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row1_subtitle2")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Family-First Features 4 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row1_subtitle3")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Family-First Features 5 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row1_subtitle4")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>

              {/* Fast & Easy Application Process
 4 row  */}

              <Grid.Row className="mt-5" style={{ background: "#fff" }}>
                <Grid.Column width={16}>
                  {" "}
                  <h3
                    className="whyus_column1"
                    style={{
                      marginTop: "0px",
                      fontSize: "18px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row2_title")}
                  </h3>
                </Grid.Column>
              </Grid.Row>
              {/* Fast & Easy Application Process
 4 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row2_subtitle1")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Fast & Easy Application Process
 4 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row2_subtitle2")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Fast & Easy Application Process
 4 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row2_subtitle3")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Fast & Easy Application Process
 4 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row2_subtitle4")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>

              {/* Fast & Easy Application Process
 4 row  */}

              <Grid.Row className="mt-5" style={{ background: "#fff" }}>
                <Grid.Column width={16}>
                  {" "}
                  <h3
                    className="whyus_column1"
                    style={{
                      marginTop: "0px",
                      fontSize: "18px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row3_title")}
                  </h3>
                </Grid.Column>
              </Grid.Row>
              {/*Honest & Uncomplicated Coverage
 4 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row3_subtitle1")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Honest & Uncomplicated Coverage
 4 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row3_subtitle2")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Honest & Uncomplicated Coverage
 4 row  */}
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row3_subtitle3")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ marginTop: "-10px", background: "#fff" }}>
                <Grid.Column width={12}>
                  {" "}
                  <h3
                    className="text-landing"
                    style={{
                      marginTop: "0px",
                      textAlign: "start",
                      padding: "0px 5px",
                    }}
                  >
                    {t("why_aplus.row3_subtitle4")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Icon
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                    }}
                    name={"large check circle  green"}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Tablet>
    </>
  );
}
