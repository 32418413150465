export const clearCacheData = (props) => {
  localStorage.setItem('cardType',props);

  console.log('clearing caches',props);
  if ("caches" in window) {
     // console.log('caches clearing');
    caches
      .keys()
      .then((names) => {
         // console.log("clearing cache");
        names.forEach((name) => {
          caches.delete(name);
        });
        
      })
      .catch((err) => {
         // console.log(err, "err cache");
      });
  }
  else {
     // console.log('caches not found');
  }
  sessionStorage.clear();
};