import React from "react";

import { Grid, } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { NavLink, useHistory } from "react-router-dom";
export default function WhyUs() {
  const history1 = useHistory()
  const { t } = useTranslation();
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  }; 
  return (
    <>
      <Desktop>
        <div className="flex w-100 flex-column">
          <div className="mt100 w-100 flex d-flex justifiy-content-between ">
            <div className="mt-5  w-100">
              <h3 className="heading-landing-gold">
                {" "}
                {t("Critical_our_services.title")}
              </h3>
              <div className="flex d-flex">
                <h3
                  className="heading-landing-blue pr-3"
                  style={{ marginTop: "-10px" }}
                >
                  {t("Home_our_services.sub_title")}
                </h3>
                <img
                  className=" d-none d-md-block"
                  style={{ marginTop: "-80px", marginLeft: "-30px" }}
                  src="./assets/img/landing/sparkle.svg"
                  alt="oversrvice"
                />
              </div>

              <h3
                className="text-landing pr-3"
                style={{ marginTop: "-10px", width: "100%" }}
              >
                {t("Critical_our_services.describe")}
              </h3>
            </div>
            <div>
              {" "}
              <img
                className="ourservicesvg"
                src="./assets/img/landing/ourservices.svg"
                alt="oversrvice"
              />
            </div>
          </div>

          <div className="mt-5 w-100">
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <div className="ourServiceCard">
                    <div
                      className="w-100"
                      style={{
                        position: "relative",

                        width: "100%",
                      }}
                      onClick={() => history1.push('quote/CI/IamFamiliar')}
                    >
                      <img
                        className="flex w-100  justify-content-center"
                        src="./assets/img/home/quick_note.svg"
                        width={"80px"}
                        style={{
                          objectFit: 'contain'
                        }}
                        alt="oversrvice"
                        height={"80px"}
                      />
                    </div>
                    <h3
                      onClick={() => history1.push('quote/CI/IamFamiliar')}
                      className="mt-1">
                      {" "}
                      {t("Critical_our_services.grid1_title")}
                    </h3>
                    <h4 className="text-landing mt-1 text-left">
                      {t("Critical_our_services.grid1_desc")}
                    </h4>
                    {/* <NavLink className="mt-1" to="quote/life/IamFamiliar">
                      {t("Home_our_services.grid1_title")}
                    </NavLink> */}
                  </div>
                </Grid.Column>
                <Grid.Column
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <div className="ourServiceCard">
                    <div
                      className="w-100"
                      style={{
                        position: "relative",

                        width: "100%",
                      }}
                      onClick={() => history1.push('quote/CI/IamNew')}
                    >
                      <img
                        className="flex w-100  justify-content-center"
                        src="./assets/img/landing/customer.png"
                        width={"80px"}
                        height={"80px"}
                        style={{
                          objectFit: 'contain'
                        }}
                        alt="oversrvice"
                      />
                    </div>
                    <h3
                      onClick={() => history1.push('quote/CI/IamNew')}
                      className="mt-1">
                      {" "}
                      {t("Critical_our_services.grid2_title")}
                    </h3>
                    <h4 className="text-landing mt-1 text-left">
                      {t("Critical_our_services.grid2_desc")}
                    </h4>
                    {/* <NavLink className="mt-1" to="quote/life/IamFamiliar">
                      {t("Home_our_services.grid2_title")}
                    </NavLink> */}
                  </div>
                </Grid.Column>
                {/* <Grid.Column
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <div className="ourServiceCard">
                    <div
                      className="w-100"
                      style={{
                        position: "relative",

                        width: "100%",
                      }}
                      onClick={() => history1.push('quote/DI/IamFamiliar')}
                    >
                      <img
                        className="flex w-100  justify-content-center"
                        src="./assets/img/landing/ourService_disability.svg"
                        width={"80px"}
                        height={"80px"}
                        alt="oversrvice"
                      />
                    </div>
                    <h3
                      onClick={() => history1.push('quote/DI/IamFamiliar')}
                      className="mt-1">
                      {" "}
                      {t("Home_our_services.grid3_title")}
                    </h3>
                    <h4 className="text-landing mt-1 text-left">
                      {t("Home_our_services.grid3_desc")}
                    </h4>
                   
                  </div>
                </Grid.Column> */}
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div
          className="flex w-100 flex-column  px-4"
          style={{
            background:
              "linear-gradient(to bottom, #ffffff, #fcfcfd, #f9f9fb, #f6f7f9, #f3f4f7)",
          }}
        >
          <div className=" w-100 flex flex-column  justifiy-content-center ">
            <div
              className="  w-100"
              style={{
                position: "relative",
                display: "block",
              }}
            >
              <h3 className="heading-landing-gold">
                {" "}
                {t("Critical_our_services.title")}
              </h3>
              <div className="flex d-flex">
                <h3
                  className="heading-landing-blue "
                  style={{
                    marginTop: "-10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {t("Home_our_services.sub_title")}
                </h3>
              </div>
              <h3
                className="text-landing"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  textAlign: "center",

                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {t("Critical_our_services.describe")}
              </h3>{" "}
              <img
                className="ourservicesvg"
                src="./assets/img/landing/ourservices.svg"
                alt="oversrvice"
              />
            </div>
          </div>

          <div
            className="mt-5 w-100"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              padded
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid.Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="ourServiceCard">
                    <div
                      className="w-100 pb-3"
                      style={{
                        position: "relative",
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                      }}
                      onClick={() => history1.push('quote/CI/IamFamiliar')}
                    >
                      <img
                        className="flex w-100  justify-content-center"
                        src="./assets/img/home/quick_note.svg"
                        width={"80px"}
                        style={{
                          objectFit: 'contain'
                        }}
                        alt="oversrvice"
                        height={"80px"}
                      />
                    </div>
                    <h3
                      onClick={() => history1.push('quote/CI/IamFamiliar')}
                      className="mt-1">
                      {" "}
                      {t("Critical_our_services.grid1_title")}
                    </h3>
                    <h4
                      className="text-landing mt-1"
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      {t("Critical_our_services.grid1_desc")}
                    </h4>
                    {/* <NavLink className="mt-1" to="quote/life/IamFamiliar">
                      {t("Home_our_services.grid1_title")}
                    </NavLink> */}
                  </div>
                </Grid.Column>
                <Grid.Column
                  className="py-4"
                  computer={16}
                  tablet={16}
                  mobile={16}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="ourServiceCard">
                    <div
                      className="w-100 pb-3"
                      style={{
                        position: "relative",
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                      }}
                      onClick={() => history1.push('quote/CI/IamNew')}
                    >
                      <img
                        className="flex w-100  justify-content-center"
                        src="./assets/img/landing/customer.png"
                        width={"80px"}
                        height={"80px"}
                        style={{
                          objectFit: 'contain'
                        }}
                        alt="oversrvice"
                      />
                    </div>
                    <h3
                      onClick={() => history1.push('quote/CI/IamNew')}
                      className="mt-1">
                      {" "}
                      {t("Critical_our_services.grid2_title")}
                    </h3>
                    <h4
                      className="text-landing mt-1"
                      style={{ textAlign: "left" }}
                    >
                      {t("Critical_our_services.grid2_desc")}{" "}
                    </h4>
                    {/* <NavLink className="mt-1" to="quote/life/IamFamiliar">
                      {t("Home_our_services.grid2_title")}
                    </NavLink> */}
                  </div>
                </Grid.Column>
                {/* <Grid.Column
                  className="py-4"
                  computer={16}
                  tablet={16}
                  mobile={16}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="ourServiceCard">
                    <div
                      className="w-100 pb-3"
                      style={{
                        position: "relative",
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                      }}
                      onClick={() => history1.push('quote/DI/IamFamiliar')}
                    >
                      <img
                        src="./assets/img/landing/ourService_disability.svg"
                        width={"80px"}
                        height={"80px"}
                        alt="oversrvice"
                      />
                    </div>
                    <h3
                      onClick={() => history1.push('quote/DI/IamFamiliar')}
                      className="mt-1">
                      {" "}
                      {t("Home_our_services.grid3_title")}
                    </h3>
                    <h4
                      className="text-landing mt-1"
                      style={{ textAlign: "left" }}
                    >
                      {t("Home_our_services.grid3_desc")}{" "}
                    </h4>
                   
                  </div>
                </Grid.Column> */}
                {/* <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="ourServiceCard">
                    <div
                      className="w-100"
                      style={{
                        position: "relative",
                        justifyContent: "end",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <img
                        src="./assets/img/landing/ourService_disability.svg"
                        width={"80px"}
                        height={"80px"}
                      />
                    </div>
                    <h3 className="mt-1"> {t("our_services.grid3_title")}</h3>
                    <h4
                      className="text-landing mt-1"
                      style={{ textAlign: "justify" }}
                    >
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups.vLorem ipsum is placeholder
                      text commonly used in the graphic, print, and publishing
                      industries for previewing layouts and visual mockups.
                    </h4>
                    <a className="mt-1">{t("our_services.grid3_title")}</a>
                  </div>
                </Grid.Column> */}
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Tablet>
    </>
  );
}
