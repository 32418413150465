import React from "react";

import { Grid, Image, Table, Icon, Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
const style = {
  marginLeft: "0px",
  marginTop: "0px",
  borderRadius: "10px",
  width: "229px",
  height: "95px",
  padding: "0px",

  /* Blue brand */
  background: "#307FD1",
  boxShadow: "0px 34px 24px -15px rgba(0, 0, 0, 0.14)",
};
const style2 = {
  marginLeft: "0px",
  marginTop: "0px",
  borderRadius: "10px",
  width: "200px",
  height: "95px",
  padding: "0px",

  /* Blue brand */
  background: "#307FD1",
  boxShadow: "0px 34px 24px -15px rgba(0, 0, 0, 0.14)",
};
export default function Baner() {
  const { t } = useTranslation();
  let history = useHistory();
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        {" "}
        <div
          className="flex w-100  mt-5 flex-column bgImgBanner"
          style={{ position: "relative", marginBottom: "0px" }}
        >
          <div className="flex d-flex w-100 justify-content-center">
            <div className="banderdiv" style={{ width: "673px" }}>
              <h2 style={{ textTransform: "none" }}> {t("banner.title")}</h2>
              <h3> {t("banner_li.describe")}</h3>
              <div className=" flex d-flex justify-content-between mt-5 gap-5">
                <button
                  className="banner_button_1"
                  onClick={() => history.push("/quote/life/IamFamiliar/")}
                >
                  {t("banner.button1")}
                </button>

                <Dropdown
                  className="banner_button_2 mt-3 ml-5"
                  text={
                    <div>
                      <p className="h8" style={{ color: "#fff" }}>
                        {" "}
                        {t("banner.button2")}
                      </p>{" "}
                    </div>
                  }
                >
                  <Dropdown.Menu style={style}>
                    <div
                      style={{
                        display: "flex",
                        flexDirecion: "column",
                        height: "30px",
                      }}
                    >
                      <a
                        href="mailto: Contact@AplusWealth.com"
                        className=" mx-1 mr-md-4 text-white support_text"
                        style={{ padding: "20px" }}
                      >
                        <Icon
                          name="envelope"
                          className="d-md-inline d-none pr-1"
                        />
                        Contact@AplusWealth.com
                      </a>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirecion: "column",
                      }}
                    >
                      <a
                        href="tel: +1-888-461-6120"
                        className="mx-1 mr-2 text-white support_text"
                        style={{ padding: "20px" }}
                      >
                        <Icon
                          name="phone"
                          flipped="horizontally"
                          className="d-md-inline d-none pr-1"
                        />
                        +1-888-461-6120
                      </a>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div
          className="flex w-100   flex-column bgImgBanner"
          style={{
            position: "relative",
            marginBottom: "0px",
            marginTop: "1.6em",
          }}
        >
          <div className="flex d-flex w-100 justify-content-center px-4">
            <div className="banderdiv">
              <h2 style={{ textTransform: "none" }}> {t("banner.title")}</h2>
              <h3> {t("banner_li.describe")}</h3>
              <div>
                <button
                  className="banner_button_1"
                  onClick={() => history.push("/quote/life/IamFamiliar/")}
                >
                  {t("banner.button1")}
                </button>
                <Dropdown
                  className="banner_button_2 mt-2 ml-3"
                  text={
                    <div>
                      <p
                        className="h8"
                        style={{ color: "#fff", marginTop: "4px" }}
                      >
                        {" "}
                        {t("banner.button2")}
                      </p>{" "}
                    </div>
                  }
                >
                  <Dropdown.Menu style={style2}>
                    <div
                      style={{
                        display: "flex",
                        flexDirecion: "column",
                        height: "30px",
                      }}
                    >
                      <a
                        href="mailto: Contact@AplusWealth.com"
                        className=" mx-1 mr-md-4 text-white support_text"
                        style={{ padding: "20px" }}
                      >
                        <Icon
                          name="envelope"
                          className="d-md-inline d-none pr-1"
                        />
                        Contact@AplusWealth.com
                      </a>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirecion: "column",
                      }}
                    >
                      <a
                        href="tel: +1-888-461-6120"
                        className="mx-1 mr-2 text-white support_text"
                        style={{ padding: "20px" }}
                      >
                        <Icon
                          name="phone"
                          flipped="horizontally"
                          className="d-md-inline d-none pr-1"
                        />
                        +1-888-461-6120
                      </a>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Tablet>
    </>
  );
}
